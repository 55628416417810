import React, {useCallback, useEffect, useState} from 'react';
import {FooterBtn, FooterBtnWrap, FooterWrap, FooterWrap_inner, Select_custom} from "./style";
import {Link, useLocation} from "react-router-dom";

const Footer = () => {
    const [mainFooter, setMainFooter] = useState(false);
    const [selectAct, setSelectAct] = useState(false);
    const [mainMb, setMainMb] = useState(false);

    const onSelect = useCallback(() => {
        setSelectAct(prev => !prev);
    },[selectAct]);

    const path = window.location.pathname;

    useEffect(() => {
        if(path === "/"){
            setMainFooter(true)
        }else{
            setMainFooter(false)
            setMainMb(false)
        }
    },[path, mainFooter, useLocation(), mainMb])

    return (
        <FooterWrap className={mainFooter ? "main" : "sub"}>
            <FooterWrap_inner>
                <a href="/" title="메인페이지로 이동">
                        {
                            mainFooter ? <img src="/img/kiwon30_logo_g.png" alt="기원테크"/> : <img src="/img/kiwon30_logo.png" alt="기원테크"/>
                        }
                </a>
                <div>
                    <div>
                        <button className="grayTxt" type="button" onClick={ () => {window.open('/popup/termPersonal', 'window', 'width=500, height=700, location=no, status=no, scrollbars=yes')}}>개인정보처리방침</button>
                        <button className="grayTxt" type="button" onClick={ () => {window.open('/popup/termEmail', 'window', 'width=500, height=700, location=no, status=no, scrollbars=yes')}}>이메일주소무단수집거부</button>
                    </div>
                    <div className="footerInfo">
                        <span>
                            [ 서울 ]   08376 서울특별시 구로구 디지털로31길 62 에이스아티스포럼 1001~1004호<br/>
                            [ 부산 ]   부산광역시 부산진구 범일로 176 로얄팰리스 2차 702호
                        </span>
                        <span>
                            [ TEL ]   02-6012-7406<br/>[ FAX ]   02-6085-4330<br/>[ E-mail ]   315@egplatforms.com
                        </span>
                        <span>

                        </span>
                        <p>COPYRIGHT © 2024 KIWONTECH ALL RIGHTS RESERVED.</p>
                    </div>
                </div>
                <div>
                    <Select_custom onClick={() => onSelect()} className={selectAct ? "active" : ""}>
                        <ul>
                            <li>
                                <a href="http://secuecloud.co.kr/" target="_blank">SECU E Cloud</a>
                            </li>
                            <li>
                                <a href="http://www.secu-email.com/" target="_blank">EG-Platform</a>
                            </li>
                        </ul>
                        <span>FAMILY SITE</span>
                    </Select_custom>
                    <FooterBtnWrap>
                        <FooterBtn href="http://pf.kakao.com/_PWFNK" target="_blank">
                            <img src="/img/ico-kakao.png" alt="기원테크 카카오톡"/>
                        </FooterBtn>
                        <FooterBtn href="https://blog.naver.com/kiwontech" target="_blank">
                            <img src="/img/ico-blog.png" alt="기원테크 블로그"/>
                        </FooterBtn>
                        <FooterBtn href="https://www.youtube.com/channel/UCjC1pEmpKxNPb2d53obp1Hg" target="_blank">
                            <img src="/img/ico-youtube.png" alt="기원테크 유튜브"/>
                        </FooterBtn>
                    </FooterBtnWrap>
                </div>
            </FooterWrap_inner>
        </FooterWrap>
    );
};

export default Footer;