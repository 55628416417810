import React from "react";
import {Routes, Route} from "react-router-dom";
import GlobalStyle from "./style/style";

import Main from "./main";
import Header from "components/header";
import Footer from "components/footer";
import Intro from "intro";
import ScrollTop from "common/ScrollTop";
import Email from "business/email";
import Groupware from "business/groupware";
import Erp from "business/erp";
import TermPersonal from "popup/TermPersonal";
import TermEmail from "popup/TermEmail";
import Si from "business/si";
import News from "./news";
import Recruit from "./recruit";
import View from "./news/view";
function App() {
  return (
      <>
        <ScrollTop/>
        <GlobalStyle/>
        <Header/>
        <Routes>
          <Route path="/*" element={<Main/>}/>
          <Route path="/" element={<Main/>}/>
          <Route path="/intro" element={<Intro/>}/>
          <Route path="/business/email" element={<Email/>}/>
          <Route path="/business/groupware" element={<Groupware/>}/>
          <Route path="/business/erp" element={<Erp/>}/>
          <Route path="/business/si" element={<Si/>}/>
          <Route path="/news/:id" element={<News/>}/>
          <Route path="/recruit" element={<Recruit/>}/>
          <Route path="/news/:view/:view" element={<View/>}/>
          <Route path="/popup/termPersonal" element={<TermPersonal/>}/>
          <Route path="/popup/termEmail" element={<TermEmail/>}/>
        </Routes>
        <Footer/>
      </>
  );
}

export default App;
