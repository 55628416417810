import React, {useCallback, useRef, useState} from 'react';
import {Article04} from "./style";
import useInput from "hooks/useInput";
import Popup from "../popup";
import axios from "axios";

const Form = () => {
    const [company, onChangeCompany, setCompany] = useInput("");
    const [email, onChangeEmail, setEmail] = useInput("");
    const [name, onChangeName, setName] = useInput("");
    const [contact, onChangeContact, setContact] = useInput("");
    const [content, onChangeContent, setContent] = useInput("");
    const [showPopup, setShowPopUp] = useState(false);
    const [check, setCheck] = useState(false);

    const companyRef = useRef();
    const emailRef = useRef();
    const nameRef = useRef();
    const contactRef = useRef();
    const contentRef = useRef();
    const checkRef = useRef();

    const emailForm = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,5}$/i
    const phoneForm = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/

    const onClickCheck = useCallback(() => {
        setCheck(prev => !prev);
    },[]);

    const onSubmit = useCallback((e) => {
        e.preventDefault();
        if(!company || !company.trim()){
            alert("기업명을 입력해주세요");
            companyRef.current.focus();
            return false;
        }else if(!email || !email.trim()) {
            alert("이메일주소를 입력해주세요");
            emailRef.current.focus();
            return false;
        }else if(!name || !name.trim()) {
            alert("이름/직책을 입력해주세요");
            nameRef.current.focus();
            return false;
        }else if(!contact || !contact.trim()) {
            alert("연락처를 입력해주세요");
            contactRef.current.focus();
            return false;
        }else if(!content || !content.trim()) {
            alert("문의내용을 입력해주세요");
            contentRef.current.focus();
            return false;
        }else if(!check){
            alert("개인정보 수집 및 이용을 동의해주세요");
            checkRef.current.focus();
            return false;
        }else if(!emailForm.test(email)){
            alert("이메일 양식을 확인해주세요");
            emailRef.current.focus();
            return false;
        }else if(!phoneForm.test(contact)){
            alert("연락처 양식을 확인해주세요");
            contactRef.current.focus();
            return false;
        }

        axios.post("https://apis.kiwontech.com/inquiry/si",{
            corName:company,
            email:email,
            mngName:name,
            phone:contact,
            contents:content
        })
            .then(res => {
                setShowPopUp(true);
                setCompany("");
                setEmail("");
                setContact("");
                setContent("");
                setName("");
                setCheck(prev => !prev);
                checkRef.current.checked = false;
            })
            .catch(error => {
                alert("오류가 발생했습니다. 관리자에게 연락 부탁드립니다.")
            })

    },[company, email, name, contact, content, check, checkRef])
    return (
        <Article04>
            <div className="article04Inner">
                <div className="article04Top">
                    <h3>SI 문의하기</h3>
                    <p>궁금하신 사항을 문의해주시면 친절하게 답해드리겠습니다.</p>
                </div>
                <form onSubmit={onSubmit}>
                    <div className="article04Form">
                        <div>
                            <label htmlFor="company">기업명</label>
                            <input type="text" name="company" id="company"
                                   value={company} onChange={onChangeCompany} placeholder="기업명을 입력해주세요." ref={companyRef}/>
                        </div>
                        <div>
                            <label htmlFor="email">이메일주소</label>
                            <input type="text" name="email" id="email"
                                   value={email} onChange={onChangeEmail} placeholder="이메일주소를 입력해주세요." ref={emailRef}/>
                        </div>
                        <div>
                            <label htmlFor="name">이름/직책</label>
                            <input type="text" name="name" id="name"
                                   value={name} onChange={onChangeName} placeholder="이름/직책을 입력해주세요." ref={nameRef}/>
                        </div>
                        <div>
                            <label htmlFor="contact">연락처</label>
                            <input type="text" name="contact" id="contact"
                                   value={contact} onChange={onChangeContact} placeholder="연락처를 입력해주세요." ref={contactRef}/>
                        </div>
                        <textarea name="content" id="content" value={content}
                                  onChange={onChangeContent} cols="30" rows="13" placeholder="문의내용을 입력해주세요." ref={contentRef}></textarea>
                    </div>
                    <div className="checkForm">
                        <label htmlFor="check">
                            <input type="checkbox" id="check" name="check" onClick={onClickCheck} ref={checkRef} checked={check}/>
                            <span>개인정보 수집 및 이용에 동의합니다.</span>
                        </label>
                        <button type="button" onClick={() => {
                            window.open('/popup/termPersonal', 'window', 'width=600, height=800, location=no, status=no, scrollbars=yes')
                        }}>[ 개인정보취급방침 보기 ]
                        </button>
                    </div>
                    <button type="submit" className="submitBtn">
                        문의하기
                    </button>
                </form>
            </div>
            {
                showPopup && <Popup setShowPopUp={setShowPopUp}/>
            }
        </Article04>
    );
};

export default Form;