import React, {useCallback, useEffect, useState} from 'react';
import {menuData} from "./menuData";
import {HeaderArea, HideNav, MenuList, MenuTrigger, NavBg, RightMenu, RightMenuList, RightMenuWrap} from "./style";
import {Link, useLocation} from "react-router-dom";

const Header = () => {
    const [active, setActive] = useState(false);
    const [activeMenu, setActiveMenu] = useState(false);
    const [mainHeader, setMainHeader] = useState(false);
    const [activeMenuIdx, setActiveMenuIdx] = useState(0);
    const [menuList] = useState(menuData)

    const onClickMenu = useCallback(() => {
        setActiveMenu(prev => !prev)
    }, [activeMenu]);

    const onClickMenuList = useCallback((idx) => {
        if (activeMenuIdx !== idx) {
            setActiveMenuIdx(idx)
        } else {
            setActiveMenuIdx(0)
        }
    }, [activeMenuIdx]);


    const path = window.location.pathname;

    useEffect(() => {
        setActiveMenu(false);
        setActiveMenuIdx(0);
        if(path === "/recruit" || path.split('/')[1] === "news"){
            setMainHeader(true)
        }else{
            setMainHeader(false)
        }
    },[path, mainHeader, useLocation()])

    if (!menuList) return false;
    return (
        <HeaderArea className={active && "active" || mainHeader && "bg-w"}>
        <h1 title="기원테크">
                <a href="/" title="메인페이지로 이동">
                    {
                        active || mainHeader ?
                            <img src="/img/kiwon30_logo.png" alt="기원테크"/> :
                            <img src="/img/kiwon30_logo_w.png" alt="기원테크"/>
                    }
                </a>
            </h1>
            <MenuList>
                <nav onMouseEnter={() => setActive(true)}
                     onMouseLeave={() => setActive(false)}>
                    <ul>
                        {
                            menuList.map(list =>
                                <li key={list.id}>
                                    <Link to={list.link} title={list.title + "페이지 이동"}>
                                        {list.title}
                                    </Link>
                                    <ul className="hideNav">
                                        {
                                            list.arr.map(item =>
                                                <li key={item.id}>
                                                    <Link to={item.link} title={item.title + "페이지 이동"} onClick={() => window.scrollTo(0, 0)}>
                                                        {item.title}
                                                    </Link>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </li>
                            )
                        }
                    </ul>
                </nav>
            </MenuList>
            <MenuTrigger onClick={onClickMenu} className={activeMenu ? "active" : ""}>
                <span></span>
                <span></span>
                <span></span>
            </MenuTrigger>
            <RightMenuWrap className={activeMenu ? "active" : ""}></RightMenuWrap>
            <RightMenu className={activeMenu ? "active" : ""}>
                <nav>
                    <RightMenuList>
                        {
                            menuList.map(list =>
                                <li key={list.id} className={activeMenuIdx === list.id ? "active" : ""}>
                                    <button onClick={() => onClickMenuList(list.id)}>{list.title}</button>
                                    <ul>
                                        {
                                            list.arr.map(item =>
                                                <li key={item.id}>
                                                    <Link to={item.link} title={item.title + "페이지 이동"}>

                                                        {item.title}

                                                    </Link>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </li>
                            )
                        }
                    </RightMenuList>
                </nav>
            </RightMenu>
        </HeaderArea>
    );
};

export default Header;