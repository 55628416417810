import React, {useEffect, useState} from 'react';
import { BoardListColgroup, BoardListTable, BoardListTd, BoardListTh, BoardListTr, Loding } from "../style";
import axios from "axios";
import {Link, useSearchParams} from "react-router-dom";
import localDateTimeConverter from "../../hooks/useLocalDateTimeConverter";
import Search from "../incloud/Search";
import Pagination from "../incloud/Pagination";
import useApi from "../../hooks/useApi";

const IR = () => {
  const [url, setUrl] = useState(null);
  useEffect(() => {
    if (window.location.search) {
      setUrl(`https://apis.kiwontech.com/board/ir?pageNo=${currentPage}${currentSearchType ? `&searchType=${currentSearchType}` : ""}${currentSearchText ? `&searchText=${currentSearchText}` : ""}`);
    } else {
      setUrl(`https://apis.kiwontech.com/board/ir`);
    }
  }, [window.location.search]);

  const data = useApi(url);
  const { apiData, isValidating, mutate } = data;
  const { noticeList, boardList } = apiData?.obj || [];
  const { paging } = apiData?.obj || {};
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = searchParams.get('pageNo') || 1;
  const currentSearchType = searchParams.get('searchType') || "";
  const currentSearchText = searchParams.get('searchText') || "";
  const [pageItem, setPageItem] = useState(Math.ceil(paging?.totalCount / 10));

  useEffect(() => {
    setPageItem(Math.ceil(paging?.totalCount / 10))
  }, [url, pageItem, apiData])
  if (!apiData) return <Loding><span>LOADING...</span></Loding>;
  return (
    <>
      <Search apiData={apiData}/>
      <BoardListTable>
        <BoardListColgroup>
          <col/>
          <col/>
          <col/>
        </BoardListColgroup>
        <thead>
        <tr>
          <BoardListTh className="al-c">번호</BoardListTh>
          <BoardListTh>제목</BoardListTh>
          <BoardListTh>작성일</BoardListTh>
        </tr>
        </thead>
        <tbody>
{/*        {
          apiData.obj?.noticeList.length !== 0 && apiData.obj?.noticeList?.map((item, idx) =>
            (
              <BoardListTr key={item.idx} className={`center ${item?.isNotice === true ? 'bold' : ''}`}>
                <BoardListTd className="center">
                  {item?.isNotice === true ? '공지' : obj?.totalCount - ((apiData?.obj?.pageNo - 1) * apiData?.obj?.pageSize + idx)}
                </BoardListTd>
                <BoardListTd>
                    <Link to={`/news/ir/${item.idx}`}>
                      <div className="listTitle">
                        {item?.title}
                      </div>
                    </Link>
                </BoardListTd>
                <BoardListTd className="center">{localDateTimeConverter(item.rgsde)}</BoardListTd>
              </BoardListTr>
            )
          )
        }*/}
        {
          noticeList?.length !== 0 && noticeList?.map((item, idx) => (
            <BoardListTr key={item.idx} className='center bold'>
              <BoardListTd className="center">
                공지
              </BoardListTd>
              <BoardListTd>
                <Link to={`/news/ir/${item.idx}`}>
                  <div className="listTitle">
                    {item?.title}
                  </div>
                </Link>
              </BoardListTd>
              <BoardListTd className="center">{localDateTimeConverter(item.rgsde)}</BoardListTd>
            </BoardListTr>
          ))
        }
        {
          boardList?.length !== 0 && boardList?.map((item, idx) => (
            <BoardListTr key={item.idx}>
              <BoardListTd className="center">
                {paging?.totalCount - ((paging?.pageNo - 1) * paging?.pageSize + idx)}
              </BoardListTd>
              <BoardListTd>
                <Link to={`/news/ir/${item.idx}`}>
                  <div className="listTitle">
                    {item?.title}
                  </div>
                </Link>
              </BoardListTd>
              <BoardListTd className="center">{localDateTimeConverter(item.rgsde)}</BoardListTd>
            </BoardListTr>
          ))
        }
        {
          boardList?.length === 0 && noticeList?.length === 0 &&
          <BoardListTr>
            <BoardListTd className="center" colSpan={3}>리스트가 없습니다.</BoardListTd>
          </BoardListTr>
        }
        </tbody>
      </BoardListTable>
      <Pagination pageItem={pageItem} apiData={apiData}/>
    </>
  );
};

export default IR;