import styled, {keyframes} from "styled-components"

const articleAni1 = keyframes`
  0%{
    opacity: 0;
    transform: translateY(100px);
  }
  100%{
    opacity: 1;
    transform: translateY(0px);
  }
`

const articleAni2 = keyframes`
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
`
export const MaxWidth =styled.div`
  max-width: 1480px;
  padding: 0 40px;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 530px) {
    max-width: 1460px;
    padding: 0 30px;
  }
`
export const Banner = styled.div`
  width: 100%;
  background-repeat:no-repeat;
  background-size: cover;
  padding: 0 100px;
  background-repeat:no-repeat;
  background-size: cover;
  padding: 0 100px;
  box-sizing: border-box;
  background-image: url("/img/img-banner02.png");
  h2{
    color:#fff;
    font-size: 70px;
    font-weight: 600;
    padding: 340px 0 90px;
    line-height: 1.4;
    animation: ${articleAni2} 2s;
    @media screen and (max-width: 840px) {
      padding: 255px 0 45px;
      font-size: 44px;
    }
    @media screen and (max-width: 640px) {
      padding: 270px 0 30px;
      font-size: 32px;
    }
    span{
      @media screen and (max-width: 640px) {
        display: block;
      }
    }
    .fc-g{
      display: block;
      color: #bcd330;
    }
  }
  @media screen and (max-width: 1300px) {
    padding: 0 40px;
  }
  @media screen and (max-width: 840px) {
    background-position: -300px;
  }
  @media screen and (max-width: 530px) {
    padding: 0 30px;
    background-position: -550px;
  }
`
export const Article01 = styled.article`
  margin: 100px 0;
  animation: ${articleAni1} 2s;
  @media screen and (max-width: 1100px) {
    margin: 50px 0 80px;
  }
  @media screen and (max-width: 530px) {
    margin: 35px 0 50px;
  }
  & > div{
    display: flex;
    @media screen and (max-width: 1100px) {
      display: block;
    }
  }
  h3{
    font-size: 42px;
    font-weight: 700;
    color: #222;
    width: 35%;
    line-height: 1.2;
    @media screen and (max-width: 1100px) {
      width: 100%;
      margin-bottom: 30px;
      font-size: 34px;
    }
    @media screen and (max-width: 530px) {
      font-size: 30px;
    }
    span{
      display: block;
      word-break: keep-all;
      &:last-of-type{
        font-weight: 500;
        font-size: 22px;
        @media screen and (max-width: 1100px) {
          font-size: 18px;
        }
        @media screen and (max-width: 530px) {
          font-size: 16px;
        }
      }
    }
  }
  p{
    width: 65%;
    @media screen and (max-width: 1100px) {
      width: 100%;
    }
    span{
      color: #636363;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.3;
      display: block;
      word-break: keep-all;
      @media screen and (max-width: 1100px) {
        font-size: 16px;
      }
      &:nth-of-type(1), &:nth-of-type(2){
        margin-bottom: 20px;
      }
    }
  }
`
export const Article02 = styled.article`
  width: 100%;
  background-image: url("/img/img-email__banner01.png");
  background-repeat: no-repeat;
  background-size: cover; 
  padding: 130px 0;
  animation: ${articleAni1} 2s;
  @media screen and (max-width: 1100px) {
    padding: 70px 0 20px;
  }
  
  .article02__inner{
    max-width: 1480px;
    padding: 0 40px;
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto;
    h3{
      color: #fff;
      font-size: 54px;
      font-weight: 500;
      text-align: left;
      line-height: 1.3;
      @media screen and (max-width: 1100px) {
        font-size: 44px;
      }
      @media screen and (max-width: 530px) {
        font-size: 32px;
      }
      @media screen and (max-width: 400px) {
        font-size: 29px;
      }
      span{
        display: block;
        color: #8d97a3;
        font-size: 24px;
        font-weight: 500;
        word-break: keep-all;
        @media screen and (max-width: 1100px) {
          font-size: 20px;
        }
        @media screen and (max-width: 530px) {
          font-size: 16px;
        }
        @media screen and (max-width: 400px) {
          font-size: 15px;
        }
      }
    }
  }
`
export const Article02Item = styled.ul`
  display: flex;
  margin-top: 80px;
  @media screen and (max-width: 1100px) {
   display: block;
  }
  li{
    width: 33.3%;
    @media screen and (max-width: 1100px) {
      width: 100%;
      margin-bottom: 80px;
    }
    div{
     width: 360px;
      height: 360px;
      border-radius: 50%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      z-index: 1;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-image: url("/img/img-emailItem.png");
      @media screen and (max-width: 1300px) {
        width: 300px;
        height: 300px;
      }
      @media screen and (max-width: 1100px) {
        width: 360px;
        height: 360px;
      }
      @media screen and (max-width: 530px) {
        width: 290px;
        height: 290px;
      }
      @media screen and (max-width: 400px) {
        width: 270px;
        height: 270px;
      }
      @media screen and (max-width: 345px) {
        width: 250px;
        height: 250px;
      }
/*      &:before{
        content: "";
        display: inline-block;
        width: 110%;
        height: 110%;
        border: 1px dashed #fff;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }*/
      h4{
        font-size: 30px;
        font-weight: 600;
        color: #fff;
        text-align: center;
        position: relative;
        z-index: 1;
        line-height: 1.3;
        @media screen and (max-width: 1300px) {
          font-size: 27px;
        }
        @media screen and (max-width: 1100px) {
          font-size: 30px;
        }
        @media screen and (max-width: 530px) {
          font-size: 26px;
        }
        @media screen and (max-width: 400px) {
          font-size: 24px;
        }
        span{
          display: block;
        }
      }
    }
    p{
      color: #fff;
      font-size: 22px;
      font-weight: 300;
      text-align: center;
      padding-top: 40px;
      margin-top: 60px;
      line-height: 1.3;
      position: relative;
      @media screen and (max-width: 1300px) {
        font-size: 19px;
      }
      @media screen and (max-width: 1300px) {
        font-size: 18px;
        padding-top: 35px;
        margin-top: 55px;
      }
      @media screen and (max-width: 400px) {
        font-size: 16px;
      }
      &:before{
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        top:0;
        left: 50%;
        transform: translateX(-50%);
        background-color: #fff;
      }
      &:after{
        content: "";
        display: inline-block;
        width: 2px;
        height: 60px;
        position: absolute;
        top:-60px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #fff;
        @media screen and (max-width: 1300px) {
          height: 55px;
          top: -55px;
        }
      }
      span{
        display: block;
        @media screen and (max-width: 350px) {
          display: inline;
          word-break: keep-all;
        }
      }
    }
  }
`
export const Article03 = styled.article`
  margin: 140px 0 190px 260px;
  @media screen and (min-width: 2000px) {
    margin: 140px auto 190px;
    max-width: 1480px;
  }
  @media screen and (max-width: 1450px) {
    margin: 140px 0 190px 40px;
  }
  @media screen and (max-width: 1100px) {
    margin: 90px 0 90px 40px;
  }
  @media screen and (max-width: 650px) {
    margin: 100px 30px;
  }
  .article03Top{
    line-height: 1.5;
    border-bottom: 2px solid #222;
    padding-bottom: 80px;
    @media screen and (max-width: 1100px) {
      padding-bottom: 50px;
    }
    @media screen and (max-width: 650px) {
      padding-bottom: 35px;
    }
    h3{
      color: #222;
      font-size: 54px;
      font-weight: 600;
      @media screen and (max-width: 1100px) {
        font-size: 44px;
      }
      @media screen and (max-width: 650px) {
        font-size: 30px;
        line-height: 1.2;
        margin-bottom: 10px;
      }
      @media screen and (max-width: 400px) {
        font-size: 28px;
      }
      span{
        color: #bcd330;
        @media screen and (max-width: 650px) {
          display: block;
        }
      }
    }
    p{
      color: #838383;
      font-size: 24px;
      font-weight: 500;
      word-break: keep-all;
      @media screen and (max-width: 1100px) {
        font-size: 20px;
      }
      @media screen and (max-width: 650px) {
        font-size: 16px;
      }
      @media screen and (max-width: 400px) {
        font-size: 14px;
      }
      span{
        @media screen and (max-width: 1100px) {
          display: block;
        }
      }
    }
  }
`
export const Article03Item = styled.ul`
  max-width: 1480px;
  padding: 0 40px;
  box-sizing: border-box;
  display: flex;
  @media screen and (min-width: 2000px) {
    max-width: 100%;
  }
  @media screen and (max-width: 1100px) {
    display: block;
    padding: 0;
  }
  li{
    width: 33.3%;
    text-align: left;
    padding-top: 120px;
    @media screen and (max-width: 1100px) {
     width: 100%;
      padding-top: 0px;
      margin-top: 70px;
    }
    &:first-of-type{
      @media screen and (max-width: 650px) {
        margin-top: 40px;
      }
    }
    &:nth-of-type(1) h4:before{
      content: "01";
    }
    &:nth-of-type(2) h4:before{
      content: "02";
    }
    &:nth-of-type(3) h4:before{
      content: "03";
    }
    h4{
      color: #222;
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 25px;
      position: relative;
      word-break: keep-all;
      line-height: 1.3;
      @media screen and (max-width: 1100px) {
        font-size: 24px;
        padding-left: 130px;
        margin-bottom: 15px;
      }
      @media screen and (max-width: 650px) {
        font-size: 24px;
        padding-left: 100px;
        margin-bottom: 10px;
      }
      @media screen and (max-width: 400px) {
        font-size: 22px;
      }
      &:before{
        display: inline-block;
        position: absolute;
        left: 0;
        top: 45px;
        z-index: -1;
        color: #bcd330;
        font-size: 100px;
        font-weight: 900;
        opacity: 0.2;
        transform: translateY(-100%);
        @media screen and (max-width: 1100px) {
          transform: translateY(-15%);
          top: 0;
          font-size: 80px;
        }
        @media screen and (max-width: 650px) {
          font-size: 60px;
        }
        @media screen and (max-width: 400px) {
          font-size: 50px;
        }
      }
      span{
        display: block;
      }
    }
    p{
      color: #838383;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.4;
      word-break: keep-all;
      @media screen and (max-width: 1100px) {
        font-size: 18px;
        padding-left: 130px;
      }
      @media screen and (max-width: 650px) {
        font-size: 16px;
        padding-left: 100px;
      }
      @media screen and (max-width: 400px) {
        font-size: 14px;
      }
      span{
        display: block;
      }
    }
  }
`
export const Article04 = styled.article`
  width: 100%;
  height: 350px;
  background-image: url("/img/img-email01.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 650px) {
    height: 300px;
  }
  div{
    text-align: center;
  }
  h3{
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 32px;
    font-weight: 600;
    line-height: 1.5;
    @media screen and (max-width: 1100px) {
      font-size: 30px;
    }
    @media screen and (max-width: 650px) {
      font-size: 26px;
    }
    @media screen and (max-width: 400px) {
      font-size: 24px;
    }
    span{
      @media screen and (max-width: 650px) {
        display: block;
      }
   }
    .dis-b__pc{
      font-size: 18px;
      font-weight: 400;
      display: block;
      @media screen and (max-width: 650px) {
        font-size: 16px;
      }
      @media screen and (max-width: 400px) {
        font-size: 14px;
      }
    }
  }
  a{
    display: inline-block;
    height: 60px;
    line-height: 60px;
    width: 330px;
    border: 1px solid #fff;
    background-color: transparent;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    border-radius: 30px;
    padding: 0 25px;
    text-align: left;
    box-sizing: border-box;
    background-image: url("/img/icon/ico-btnRightArrow.png");
    background-repeat: no-repeat;
    background-position: 295px center;
    margin-top: 30px;
    &:hover{
      background-image: url("/img/icon/ico-btnRightArrow-g.png");
      color: #bcd330;
      border-color: #bcd330;
      transition: 0.3s;
    }
    @media screen and (max-width: 650px) {
      font-size: 16px;
      height: 45px;
      line-height: 45px;
      width: 300px;
      background-position: 265px center;
    }
  }
`