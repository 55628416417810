import React from 'react';
import {Wrap} from "./style";

const Popup = ({setShowPopUp}) => {
    return (
        <Wrap>
            <div>
                <h2>문의완료</h2>
                <p>
                    <span>문의하기 접수가 완료되었습니다.</span>
                    <span>담당자 확인 후 입력한 연락처로 <span>연락드리겠습니다.</span></span>
                    <span>감사합니다.</span>
                </p>
                <button type="button" onClick={() => setShowPopUp(false)}>확인</button>
            </div>
        </Wrap>
    );
};

export default Popup;