import { useRef, useEffect, useCallback } from 'react';
import useSWR from 'swr';
import fetcher from './fetcher';
import { useLocation, useNavigate } from 'react-router-dom';

function laggy(useSWRNext) {
  return (key, fetcher, config) => {
    const laggyDataRef = useRef();
    const swr = useSWRNext(key, fetcher, config);

    useEffect(() => {
      if (swr.data !== undefined) {
        laggyDataRef.current = swr.data;
      }
    }, [swr.data]);

    const resetLaggy = useCallback(() => {
      laggyDataRef.current = undefined;
    }, []);

    const dataOrLaggyData = swr.data === undefined ? laggyDataRef.current : swr.data;

    const isLagging = swr.data === undefined && laggyDataRef.current !== undefined;

    return Object.assign({}, swr, {
      data: dataOrLaggyData,
      isLagging,
      resetLaggy,
    });
  };
}

const useApi = (url) => {
  const {
    data: apiData,
    mutate,
    isValidating,
  } = useSWR(url, fetcher, {
    use: [laggy],
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    onErrorRetry: (res) => {
      if (res.status !== 200 || res.status !== 302) return;
    },
  });

  return { apiData, mutate, isValidating };
};

export default useApi;
