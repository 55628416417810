import styled, {keyframes} from "styled-components"

export const MaxWidth = styled.div`
  max-width: 1480px;
  padding: 0 40px;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 530px) {
    max-width: 1460px;
    padding: 0 30px;
  }
`

export const H2 = styled.h2`
  color: #191919;
  font-size: 40px;
  font-weight: 700;
  margin: 180px 0 80px;
  word-break: keep-all;
  line-height: 1.3;
  @media screen and (max-width: 800px) {
    font-size: 28px;
    margin: 150px 0 60px;
  }
  @media screen and (max-width: 500px) {
    font-size: 26px;
    margin: 130px 0 50px;
  }
  b{
    font-size: 54px;
    @media screen and (max-width: 800px) {
      font-size: 40px;
    }
    @media screen and (max-width: 500px) {
      font-size: 36px;
    }
  }
  span{
    @media screen and (max-width: 500px) {
      display: block;
    }
  }
`
export const TabBtnGroup = styled.div`
  border-bottom: 1px solid #dddddd;
  padding: 30px 0;
  margin-bottom: 45px;
  @media screen and (max-width: 800px) {
    padding: 20px 0;
    margin-bottom: 55px;
  }
  @media screen and (max-width: 500px) {
    padding: 15px 0;
    margin-bottom: 45px;
    display: flex;
    justify-content: space-between;
  }
  button{
    color: #8f918f;
    border: 0;
    background-color: transparent;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 10px;
    margin-right: 50px;
    cursor: pointer;
    @media screen and (max-width: 800px) {
      font-size: 16px;
      padding-bottom: 7px;
    }
    @media screen and (max-width: 500px) {
      margin-right: 0px;
    }
    @media screen and (max-width: 330px) {
      font-size: 14px;
    }
    &:last-of-type{
      margin-right: 0;
    }
    &.active{
      color: #191919;
      font-size: 18px;
      font-weight: 600;
      border-bottom: 2px solid #191919;
      @media screen and (max-width: 330px) {
        font-size: 14px;
      }
    }
  }
`
export const SearchWrap = styled.div`
  padding: 25px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 800px) {
    padding: 0;
    padding-bottom: 15px;
    flex-wrap: wrap-reverse;
  }
  h4{
    color: #282828;
    font-size: 16px;
    font-weight: 600;
    @media screen and (max-width: 800px) {
      font-size: 14px;
      margin-top: 25px;
    }
    @media screen and (max-width: 800px) {
      margin-top: 15px;
    }
    span{
      font-size: 18px;
      @media screen and (max-width: 800px) {
        font-size: 16px;
      }
    }
  }
  & > div{
    @media screen and (max-width: 800px) {
      width: 100%;
    }
    form{
      display: flex;
      align-items: center;
      @media screen and (max-width: 800px) {
        width: 100%;
      }
    }
    select{
      height: 50px;
      width: 200px;
      border: 1px solid #bcbcbc;
      padding-left: 25px;
      padding-right: 30px;
      margin-right: 20px;
      color: #999999;
      font-size: 15px;
      font-weight: 600;
      background-image: url("/img/icon/ico-select.png");
      background-position: 90% center;
      background-repeat: no-repeat;
      appearance: none;
      @media screen and (max-width: 800px) {
        height: 45px;
        width: 170px;
      }
      @media screen and (max-width: 500px) {
        padding-left: 10px;
        font-size: 13px;
        height: 40px;
        width: 110px;
        margin-right: 10px;
      }
    }
  }
  .searchBox{
    position: relative;
    width: 400px;
    height: 50px;
    @media screen and (max-width: 800px) {
      height: 45px;
      width: calc(100% - 190px);
    }
    @media screen and (max-width: 500px) {
      height: 40px;
      width: calc(100% - 120px);
    }
    input{
      height: 100%;
      width: 100%;
      border: 1px solid #bcbcbc;
      padding-left: 25px;
      padding-right: 70px;
      color: #999999;
      font-size: 15px;
      font-weight: 600;
      box-sizing: border-box;
      position: absolute;
      @media screen and (max-width: 500px) {
        padding-left: 10px;
        font-size: 13px;
      }
    }
    button{
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      border: 0;
      background-color: transparent;
      background-image: url("/img/icon/ico-search.png");
      background-repeat: no-repeat;
      font-size: 0;
      background-position: center;
      width: 60px;
      cursor: pointer;
      @media screen and (max-width: 500px) {
        width: 40px;
      }
    }
  }
`
export const List = styled.ul`
  li{
    &:first-of-type{
      a{
        border-top: 1px solid #dddddd;
      }
    }
    &.no-data{
      text-align: center;
      padding: 200px 0 ;
      font-size: 18px;
    }
    a{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 35px 0;
      border-bottom: 1px solid #dddddd;
      @media screen and (max-width: 900px) {
        flex-wrap: wrap;
      }
      @media screen and (max-width: 500px) {
        padding: 40px 0;
      }
    }
    .imgWrap{
      width: 410px;
      height: 265px;
      overflow: hidden;
      margin-right: 80px;
      img{
        height: 100%;
        width: 100%;
      }
      @media screen and (max-width: 900px) {
        width: 100%;
        height: 260px;
        margin-right: 0;
      }
      @media screen and (max-width: 500px) {
        height: 210px;
      }
    }
    .textWrap{
      width: calc(100% - 490px);
      @media screen and (max-width: 900px) {
        width: 100%;
        margin-top: 25px;
      }
      h3{
        color: #282828;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 25px;
        line-height: 1.3;
        @media screen and (max-width: 900px) {
          margin-bottom: 15px;
          font-size: 22px;
        }
        @media screen and (max-width: 500px) {
          margin-bottom: 15px;
          font-size: 20px;
        }
      }
      p{
        color: #282828;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.8;
        margin-bottom: 20px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 3;
        height: 80px;
        @media screen and (max-width: 900px) {
          margin-bottom: 15px;
        }
        @media screen and (max-width: 500px) {
          margin-bottom: 20px;
        }
      }
      span{
        color: #999999;
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
`
export const NsList = styled.ul`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  li{
    width: 31%;
    margin-bottom: 70px;
    margin-right: 3.5%;
    &:nth-of-type(3n){
      margin-right: 0;
    }
    &.no-data{
      text-align: center;
      padding: 150px 0;
      font-size: 18px;
      width: 100%;
    }
    @media screen and (max-width: 800px) {
      width: 100%;
      margin-bottom: 60px;
    }
    &:nth-child(n+4):nth-child(-n+6){
      margin-bottom: 50px;
      @media screen and (max-width: 800px) {
        margin-bottom: 60px;
      }
      @media screen and (max-width: 500px) {
        margin-bottom: 40px;
      }
      &:last-of-type{
        margin-bottom: 0;
      }
    }
    .imgWrap{
      width: 100%;
      height: 485px;
      overflow: hidden;
      img{
        width: 100%;
      }
    }
    h3{
      margin-top: 30px;
      font-size: 24px;
      color: #282828;
      font-weight: 600;
      text-align: center;
      @media screen and (max-width: 500px) {
        font-size: 20px;
      }
    }
  }
`
export const BoardListTable = styled.table`{
    width: 100%;
    border-collapse: collapse;
    border-top: 1px solid black;
    border-bottom: 1px solid rgb(221, 221, 221);
}`
export const BoardListColgroup = styled.colgroup`{
    & col:nth-child(1) {
        width: 10%;
    }
    & col:nth-child(2) {
        width: 80%;
    }
    & col:nth-child(3) {
        width: 10%;
    }
    @media screen and (max-width: 900px) {
        & col:nth-child(1) {
            width: auto;
        }
        & col:nth-child(2) {
            width: auto;
        }
        & col:nth-child(3) {
            width: auto;
        }
    }
}`
export const BoardListThead = styled.thead`{
}`
export const BoardListTr = styled.tr`{
    &.bold {
        font-weight: bold;   
    }
}`
export const BoardListTh = styled.th`{
    padding: 15px;
    border-bottom: 1px solid rgb(185, 185, 185);
    @media screen and (max-width: 900px) {
        padding: 10px 5px;
        font-size: 14px;
    }
}`
export const BoardListTd = styled.td`{
    //padding: 20px 15px;
    padding: 15px;
    border-bottom: 1px solid rgb(221, 221, 221);
    .listTitle {
        width: 45vw;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    a {
        color: #000;
    }
    &.center {
        text-align: center;
    }
    &.content {
        @media screen and (max-width: 450px) {
            max-width: 200px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        @media screen and (max-width: 350px) {
            max-width: 150px;
        }
    }
    @media screen and (max-width: 900px) {
        padding: 10px 5px;
        font-size: 14px;
    }
}`
export const PaginationWrap = styled.div`
  text-align: center;
  width: 100%;
  margin: 80px 0 140px;
  @media screen and (max-width: 900px) {
    margin: 70px 0 120px;
  }
  @media screen and (max-width: 500px) {
    margin: 55px 0 80px;
  }
  button{
    border: 0;
    background: transparent;
    color: #adadad;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    padding: 0 10px;
    margin-right: 10px;
    @media screen and (max-width: 900px) {
      font-size: 20px;
    }
    @media screen and (max-width: 420px) {
      font-size: 17px;
      margin-right: 5px;
      padding: 0 7px;
    }
    &:last-of-type{
      margin-right: 0;
    }
    &.active{
      font-weight: 600;
      color: #616161;
    }
  }
`
export const Loding = styled.div`
  font-size: 20px;
  text-align: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`