import React from 'react';
import {Article01, Article02, Article02Item, Article03, Banner, Article03Item, Article04, MaxWidth} from "./style";


const Email = () => {
    return (
        <section>
            <Banner>
                <h2>
                    All-in-One Email <span>Security Solution,</span>
                    <span className="fc-g">EG-Platform</span>
                </h2>
            </Banner>
            <MaxWidth>
                <Article01>
                    <div>
                        <h3>
                            <span>이메일 보안 솔루션</span>
                            <span>EG-Platform</span>
                            <span>(이지플랫폼)</span>
                        </h3>
                        <p>
                            <span>일상적인 이메일 업무 중 평범하지 않은 메일을 받아보셨나요? 사이버 위협의 91%는 이메일에서 시작됩니다.</span>
                            <span>기원테크는 사용자의 안전한 메일 사용을 위해 메일 보안에 필요한 모든 것을 하나로 담았습니다.</span>
                            <span>급증하고 있는 지능형 이메일 공격(APT)과 발신메일 보안 이슈를</span>
                            <span>EG-Platform(Email Gateway Platform)으로 한 번에 해결하세요.</span>
                        </p>
                    </div>
                </Article01>
            </MaxWidth>
            <Article02>
                <div className="article02__inner">
                    <h3>
                        왜 EG-Platform일까요?
                        <span>수신부터 발신까지, 이메일 전 구간을 철저하게 보호합니다.</span>
                    </h3>
                    <Article02Item>
                        <li>
                            <div>
                                <h4>
                                    <span>강력한</span>
                                    수신메일 보안
                                </h4>
                            </div>
                            <p>
                                <span>고도화되고 있는 메일 지능형 공격 대응,</span>
                                사기메일 및 신종 랜섬웨어 차단
                            </p>
                        </li>
                        <li>
                            <div>
                                <h4>
                                    <span>발신메일까지</span>
                                    통합 보안
                                </h4>
                            </div>
                            <p>
                                <span>기업의 정보 자산을 담고 있는 발신메일 관리,</span>
                                정보 유출 및 오발송 피해 방지
                            </p>
                        </li>
                        <li>
                            <div>
                                <h4>
                                    <span>학습 데이터 기반</span>
                                    선제 대응
                                </h4>
                            </div>
                            <p>
                                <span>학습 데이터가 축적될 수록</span>
                                더욱 정교해지는 능동형 기업 맞춤 보안
                            </p>
                        </li>
                    </Article02Item>
                </div>
            </Article02>
            <Article03>
                <div className="article03Top">
                    <h3>
                        <span>EG-Platform</span> 선택이 탁월한 이유,
                    </h3>
                    <p>
                        기원테크는 자체 개발 메일서버의 운영 경험을 토대로 <span>이메일에 특화된 보안 기술을 보유한 전문가입니다.</span>
                    </p>
                </div>
                <Article03Item>
                    <li>
                        <h4>
                            <span>국내 유일</span>
                            Gartner 보고서 등재
                        </h4>
                        <p>
                            <span>이메일 보안의 4대 핵심기술을 모두 보유,</span>
                            지역을 대표하는 이메일 보안 Vendor로 선정
                        </p>
                    </li>
                    <li>
                        <h4>
                            <span>국내 최다 26건</span>
                            메일 관련 국내·외 특허 보유
                        </h4>
                        <p>
                            <span>다양한 이메일 편의 기능과 </span>
                            차세대 이메일 보안 기술을 가장 많이 보유
                        </p>
                    </li>
                    <li>
                        <h4>
                            <span>표적형 이메일 공격 차단을 위한</span>
                            국내 보안 표준으로 채택
                        </h4>
                        <p>
                            <span>이메일 보안에 대해 가장 잘 아는 전문가로서</span>
                            보안 기능의 Standard를 제시
                        </p>
                    </li>
                </Article03Item>
            </Article03>
            <Article04>
                <div>
                    <h3>
                        <span className="dis-b__pc">수신부터 발신까지, 이메일 보안 안전 지대</span>
                        <span>EG-Platform에</span> 대해 더 자세히 알아보세요
                    </h3>
                    <a href="https://eg-platform.com/" target="_blank">EG-Platform 홈페이지 바로가기</a>
                </div>
            </Article04>
        </section>
    );
};

export default Email;