import styled, { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'

const GlobalStyle = createGlobalStyle`
  ${reset}
  @font-face {
        font-family: "Pretendard";        
         font-style: normal;
         font-weight: 300;
         src: url(/fonts/Pretendard-Light.woff) format('font-woff')
    }
      @font-face {
        font-family: "Pretendard";        
         font-style: normal;
         font-weight: 400;
         src: url(/fonts/Pretendard-Regular.woff) format('font-woff')
    }
      @font-face {
        font-family: "Pretendard";        
         font-style: normal;
         font-weight: 500;
         src: url(/fonts/Pretendard-Medium.woff) format('font-woff')
    }
      @font-face {
        font-family: "Pretendard";        
         font-style: normal;
         font-weight: 600;
         src: url(/fonts/Pretendard-Bold.woff) format('font-woff')
    }
      @font-face {
        font-family: "Pretendard";        
         font-style: normal;
         font-weight: 700;
         src: url(/fonts/Pretendard-ExtraBold.woff) format('font-woff')
    }
  :lang(ko),html,body {
    font-family:"Pretendard",'Apple SD Gothic Neo',Sans-serif;
  }
  body{
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    @media screen and (max-width: 1300px) {
      overflow-y:auto!important;
    }
    &::-webkit-scrollbar{ 
      /* 스크롤바 막대 너비 설정 */ width: 6px; 
    }
    /* 스크롤바 막대 설정*/
    &::-webkit-scrollbar-thumb{
      /* 스크롤바 막대 높이 설정 */
      height: 17%; 
      background-color: #888; 
      /* 스크롤바 둥글게 설정 */ 
      border-radius: 10px; 
    } /* 스크롤바 뒷 배경 설정*/
    &::-webkit-scrollbar-track{ 
      background-color: rgba(0,0,0,0); 
    }
  }
  a{
    text-decoration: none;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #1b1f25 inset;
    box-shadow: 0 0 0 1000px #1b1f25 inset;
    -webkit-text-fill-color: #dbdbdb;
  }
`
export default GlobalStyle;