import React, {useCallback, useEffect} from 'react';
import {PaginationWrap} from "../style";
import useDidMountEffect from "hooks/useDidMountEffect";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import useLocalStorage from "hooks/useLocalStorage";


const Pagination = ({pageItem}) => {
    const [pageArr, setPageArr] = useLocalStorage("pageArr",0);
    const [pageParams, setPageParams] = useSearchParams();
    const currentPage = pageParams.get('pageNo') || 1;
    const navigate = useNavigate();
    const location = useLocation();

    let result = [];
    let pageList = [];

    for(let i = 1; i <= pageItem; i++){
        result.push(i)
    };

    for(let i=0; i< result.length; i+=5) {
        pageList.push(result.slice(i, i + 5));
    };

    const onClickPage = useCallback((idx) => {
        if (currentPage) {
            pageParams.set('pageNo', idx);
        } else {
            pageParams.append('pageNo', idx);
        }
        setPageParams(pageParams);
    },[currentPage, pageParams]);

    const onClickNext = useCallback(() => {
        if(currentPage < result.length){
            pageParams.set('pageNo', +currentPage + 1);
        }else {
            return
        }
        if(currentPage % 5 === 0){
            setPageArr(prev => prev + 1)
        }
        setPageParams(pageParams);
    },[currentPage, pageParams,result,pageArr])

    const onClickLast = useCallback(() => {
        pageParams.set('pageNo', result.length);
        setPageParams(pageParams);
        setPageArr(pageList.length - 1)
    },[currentPage, pageArr, pageList, result])

    const onClickPrev = useCallback(() => {
        if(currentPage > 1){
            pageParams.set('pageNo', +currentPage - 1);
            setPageParams(pageParams);
        }else {
            return
        }
        if (currentPage !== 1 && currentPage % 5 === 1) {
            setPageArr(prev => prev - 1)
        }

    },[currentPage,pageParams, pageArr])

    const onClickFirst = useCallback(() => {
        pageParams.set('pageNo', 1);
        setPageArr(0)
        setPageParams(pageParams);
    },[currentPage, pageArr])



      useEffect(() => {
          setPageArr( Math.floor(((currentPage - 1) / 5)));
          window.scrollTo({
              top: 0,
              behavior: 'smooth',
          });
        },[location.search]);

    if(currentPage <= 0) {
        pageParams.set('pageNo', 1);
    }

    return (
        <PaginationWrap>
            <button type="button" onClick={() => onClickFirst()}>
                <img src="/img/icon/ico-first.png" alt="첫페이지 이동"/>
            </button>
            <button type="button" onClick={() => onClickPrev()}>
                <img src="/img/icon/ico-prev.png" alt="이전페이지 이동"/>
            </button>
            {
                pageList[pageArr]?.map(item =>
                    <button type="button" onClick={() => onClickPage(item)}
                            key={item} className={currentPage == item ? "active" : ""}>{item}</button>
                )
            }
            <button type="button" onClick={() => onClickNext()}>
                <img src="/img/icon/ico-next.png" alt="다음페이지 이동"/>
            </button>
            <button type="button" onClick={() => onClickLast()}>
                <img src="/img/icon/ico-last.png" alt="마지막페이지 이동"/>
            </button>
        </PaginationWrap>
    );
};

export default Pagination;