import React, {useCallback, useEffect, useState} from 'react';
import {H2, Loding, MaxWidth, TabBtnGroup} from "./style";
import Search from "./incloud/Search";
import NewsList from "./components/NewsList";
import Pagination from "./incloud/Pagination";
import Securityissue from "./components/Securityissue";
import Newsletter from "./components/Newsletter";
import IR from "./components/IR";
import {useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import useLocalStorage from "hooks/useLocalStorage";
import useInput from "../hooks/useInput";

const News = () => {
    const [newsTab, setNewsTab] = useState("news");

    const navigate = useNavigate();

    const path = window.location.pathname;

    let location = useLocation();

    const onClickNewsTab = useCallback((id) => {
        setNewsTab(id);
        navigate(`/news/${id}?pageNo=1`);
    },[newsTab]);

    useEffect(() => {
        switch (path){
            case "/news/news" :
                setNewsTab("news")
                break;
            case "/news/security-issue" :
                setNewsTab("security-issue")
                break;
            case "/news/news-letter" :
                setNewsTab("news-letter")
                break;
            case "/news/ir" :
                setNewsTab("ir")
                break;
            default:
                setNewsTab("news")
        }
    },[path]);



    return (
        <section>
            <MaxWidth>
                <H2>기원테크의 성장은 <span><b>‘현재 진행형’</b>입니다.</span></H2>
                <TabBtnGroup>
                    <button type="button" className={newsTab === "ir" ? "active" : ""}
                            onClick={() => onClickNewsTab("ir")}>IR
                    </button>
                    <button type="button" className={newsTab === "news" ? "active" : ""}
                            onClick={() => onClickNewsTab("news")}>뉴스
                    </button>
                    <button type="button" className={newsTab === "security-issue" ? "active" : ""}
                            onClick={() => onClickNewsTab("security-issue")}>보안이슈
                    </button>
                    <button type="button" className={newsTab === "news-letter" ? "active" : ""}
                            onClick={() => onClickNewsTab("news-letter")}>뉴스레터
                    </button>
                </TabBtnGroup>
                {
                  newsTab === "news" && <NewsList/>
                }
                {
                  newsTab === "security-issue" && <Securityissue/>
                }
                {
                    newsTab === "news-letter" &&  <Newsletter/>
                }
                {
                  newsTab === "ir" &&  <IR/>
                }
            </MaxWidth>
        </section>
    );
};

export default News;