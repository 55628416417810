import React, {useEffect, useState} from 'react';
import {List, Loding} from "../style";
import axios from "axios";
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import localDateTimeConverter from "../../hooks/useLocalDateTimeConverter";
import removeTags from "../../hooks/removeTags";
import Search from "../incloud/Search";
import Pagination from "../incloud/Pagination";
import useLocalStorage from "../../hooks/useLocalStorage";
import useInput from "../../hooks/useInput";
import LazyImage from "../../hooks/useLazeImg";
import useApi from "../../hooks/useApi";

const Securityissue = () => {
    const [url, setUrl] = useState(null);
    useEffect(() => {
        if (window.location.search) {
            setUrl(`https://apis.kiwontech.com/board/security-issue?pageNo=${currentPage}${currentSearchType ? `&searchType=${currentSearchType}` : ""}${currentSearchText ? `&searchText=${currentSearchText}` : ""}`);
        } else {
            setUrl(`https://apis.kiwontech.com/board/security-issue`);
        }
    }, [window.location.search]);

    const data = useApi(url);
    const { apiData, isValidating, mutate } = data;
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = searchParams.get('pageNo') || 1;
    const currentSearchType = searchParams.get('searchType') || "";
    const currentSearchText = searchParams.get('searchText') || "";
    const [pageItem, setPageItem] = useState(Math.ceil(apiData?.totalCount / 10));
    useEffect(() =>{
        setPageItem(Math.ceil(apiData?.totalCount / 10))
    },[url, pageItem, apiData])
    if (!apiData) return <Loding><span>LOADING...</span></Loding>;
    return (
        <>
            <Search apiData={apiData}/>
            <List>
                {
                    apiData.list.length !== 0 ?
                        apiData.list?.map(item =>
                            (
                                <li key={item.idx}>
                                    <Link to={`/news/security-issue/${item.idx}`}>
                                        <div className="imgWrap">
                                            <LazyImage
                                                src={`https://apis.kiwontech.com/file/download/${item.mainImgIdx}`}
                                                alt={item.title}/>
                                        </div>
                                        <div className="textWrap">
                                            <h3>{item.title}</h3>
                                            <p>
                                                {removeTags(item.contents)}
                                            </p>
                                            <span>{localDateTimeConverter(item.rgsde)}</span>
                                        </div>
                                    </Link>
                                </li>
                            )
                        ) :
                        <li className="no-data">리스트가 없습니다.</li>
                }

            </List>
            <Pagination pageItem={pageItem} apiData={apiData}/>

        </>
    );
};

export default Securityissue;