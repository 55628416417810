import styled, {keyframes} from "styled-components"

const articleAni1 = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`

const articleAni2 = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
export const MaxWidth = styled.div`
  max-width: 1480px;
  padding: 0 40px;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 530px) {
    max-width: 1460px;
    padding: 0 30px;
  }
`
export const Banner = styled.div`
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 100px;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 100px;
  box-sizing: border-box;
  background-image: url("/img/img-banner04.png");

  h2 {
    color: #fff;
    font-size: 70px;
    font-weight: 600;
    padding: 340px 0 90px;
    line-height: 1.4;
    animation: ${articleAni2} 2s;
    @media screen and (max-width: 840px) {
      padding: 255px 0 45px;
      font-size: 44px;
    }
    @media screen and (max-width: 640px) {
      padding: 270px 0 30px;
      font-size: 32px;
    }

    span {
      @media screen and (max-width: 640px) {
        display: block;
      }
    }

    .fc-g {
      display: block;
      color: #bcd330;
    }
  }

  @media screen and (max-width: 1300px) {
    padding: 0 40px;
  }
  @media screen and (max-width: 840px) {
    background-position: -300px;
  }
  @media screen and (max-width: 530px) {
    padding: 0 30px;
    background-position: -550px;
  }
`
export const Article01 = styled.article`
  margin: 100px 0;
  animation: ${articleAni1} 2s;
  @media screen and (max-width: 1100px) {
    margin: 50px 0 80px;
  }
  @media screen and (max-width: 530px) {
    margin: 35px 0 50px;
  }

  & > div {
    display: flex;
    @media screen and (max-width: 1100px) {
      display: block;
    }
  }

  h3 {
    font-size: 42px;
    font-weight: 700;
    color: #222;
    width: 40%;
    line-height: 1.2;
    @media screen and (max-width: 1100px) {
      width: 100%;
      margin-bottom: 30px;
      font-size: 34px;
    }
    @media screen and (max-width: 530px) {
      font-size: 30px;
    }

    span {
      display: block;
      word-break: keep-all;

      &:last-of-type {
        font-weight: 500;
        font-size: 22px;
        @media screen and (max-width: 1100px) {
          font-size: 18px;
        }
        @media screen and (max-width: 530px) {
          font-size: 16px;
        }
      }
    }
  }

  p {
    width: 60%;
    @media screen and (max-width: 1100px) {
      width: 100%;
    }

    span {
      color: #636363;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.3;
      display: block;
      word-break: keep-all;
      @media screen and (max-width: 1100px) {
        font-size: 16px;
      }

      &:nth-of-type(1), &:nth-of-type(3) {
        margin-bottom: 20px;
      }
    }
  }
`
export const Article02 = styled.article`
  width: 100%;
  background-image: url("/img/img-erp01.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 130px 0;
  animation: ${articleAni1} 2s;
  @media screen and (max-width: 1150px) {
    padding: 90px 0 120px;
  }
  @media screen and (max-width: 480px) {
    padding: 80px 0 100px;
  }

  .article02__inner {
    max-width: 1480px;
    padding: 0 40px;
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto;
    @media screen and (max-width: 480px) {
      padding: 0 30px;
    }
    h3 {
      color: #fff;
      font-size: 54px;
      font-weight: 500;
      text-align: left;
      line-height: 1.3;
      @media screen and (max-width: 1150px) {
        font-size: 44px;
      }
      @media screen and (max-width: 530px) {
        font-size: 32px;
      }
      @media screen and (max-width: 400px) {
        font-size: 29px;
      }
      @media screen and (max-width: 370px) {
        font-size: 26px;
      }
      span {
        display: block;
        color: #8d97a3;
        font-size: 24px;
        font-weight: 500;
        word-break: keep-all;
        @media screen and (max-width: 1150px) {
          font-size: 22px;
        }
        @media screen and (max-width: 530px) {
          font-size: 16px;
        }
        @media screen and (max-width: 400px) {
          font-size: 15px;
        }
        @media screen and (max-width: 370px) {
          font-size: 14px;
        }
      }
    }
  }
`
export const Article02Item = styled.ul`
  display: flex;
  margin-top: 80px;
  @media screen and (max-width: 1150px) {
    display: block;
  }

  li {
    width: 33.3%;
    @media screen and (max-width: 1150px) {
      width: 100%;
      margin-bottom: 100px;
    }
    &:last-of-type{
      margin-bottom: 0;
    }
    &:nth-of-type(1) > div {
      background-image: url("/img/img-erpItem01.png");
    }

    &:nth-of-type(2) > div {
      background-image: url("/img/img-erpItem02.png");
      position: relative;
      margin: 0 auto;

      &:before {
        content: "";
        display: inline-block;
        max-width: 100px;
        width: 100%;
        height: 1px;
        background-color: #cdd2d6;
        position: absolute;
        left: -100px;
        top: 50%;
        transform: translateY(-50%);
        @media screen and (max-width: 1440px) {
          display: none;
        }
        @media screen and (max-width: 1150px) {
          display: inline-block;
        }
      }

      &:after {
        content: "";
        display: inline-block;
        max-width: 100px;
        width: 100%;
        height: 1px;
        background-color: #cdd2d6;
        position: absolute;
        right: -100px;
        top: 50%;
        transform: translateY(-50%);
        @media screen and (max-width: 1440px) {
          display: none;
        }
        @media screen and (max-width: 1150px) {
          display: inline-block;
        }
      }
    }

    &:nth-of-type(3) {
      display: flex;
      justify-content: end;

      & > div {
        background-image: url("/img/img-erpItem03.png");
      }
    }

    & > div {
      width: 400px;
      height: 400px;
      border-radius: 50%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      z-index: 1;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      div {
        @media screen and (max-width: 1150px) {
          transform: rotate(270deg);
        }
      }

      @media screen and (max-width: 1150px) {
        transform: rotate(90deg);
      }
      @media screen and (max-width: 1300px) {
        width: 350px;
        height: 350px;
      }
      @media screen and (max-width: 1150px) {
        width: 400px;
        height: 400px;
        margin: 0 auto;
      }
      @media screen and (max-width: 480px) {
        width: 330px;
        height: 330px;
        background-size: contain;
      }
      @media screen and (max-width: 370px) {
        max-width: 280px;
        max-height: 280px;
        width: 100%;
      }
      @media screen and (max-width: 370px) {
        max-width: 280px;
        max-height: 280px;
        width: 100%;
      }
      h4 {
        font-size: 30px;
        font-weight: 600;
        color: #fff;
        text-align: center;
        line-height: 1.3;
        @media screen and (max-width: 1300px) {
          font-size: 26px;
        }
        @media screen and (max-width: 1150px) {
          font-size: 30px;
        }
        @media screen and (max-width: 480px) {
          font-size: 24px;
        }
        @media screen and (max-width: 370px) {
          font-size: 20px;
        }
        span {
          display: block;
        }
      }
    }

    p {
      color: #fff;
      font-size: 18px;
      font-weight: 200;
      text-align: center;
      line-height: 1.5;
      margin-top: 20px;
      word-break: keep-all;
      @media screen and (max-width: 1300px) {
        font-size: 16px;
      }
      @media screen and (max-width: 1150px) {
        font-size: 18px;
      }
      @media screen and (max-width: 480px) {
        font-size: 14px;
        padding: 0 30px;
      }
      @media screen and (max-width: 370px) {
        font-size: 13px;
      }
      .br {
        display: block;
        word-break: keep-all;
        @media screen and (max-width: 480px) {
          display: inline;
        }
      }
    }
  }
`
export const Article03 = styled.article`
  margin: 140px 0 190px 260px;
  @media screen and (min-width: 2000px) {
    margin: 140px auto 190px;
    max-width: 1480px;
  }
  @media screen and (max-width: 1450px) {
    margin: 140px 0 190px 40px;
  }
  @media screen and (max-width: 1150px) {
    margin: 90px 0 90px 40px;
  }
  @media screen and (max-width: 650px) {
    margin: 100px 30px;
  }
  @media screen and (max-width: 480px) {
    margin: 80px 20px;
  }
  .article03Top {
    line-height: 1.5;
    border-bottom: 2px solid #222;
    padding-bottom: 80px;
    @media screen and (max-width: 1150px) {
      padding-bottom: 50px;
    }
    @media screen and (max-width: 650px) {
      padding-bottom: 35px;
    }

    h3 {
      color: #222;
      font-size: 54px;
      font-weight: 600;
      @media screen and (max-width: 1150px) {
        font-size: 44px;
      }
      @media screen and (max-width: 650px) {
        font-size: 30px;
        line-height: 1.2;
        margin-bottom: 10px;
      }
      @media screen and (max-width: 400px) {
        font-size: 28px;
      }

      span {
        color: #bcd330;
        @media screen and (max-width: 650px) {
          display: block;
        }
      }
    }

    p {
      color: #838383;
      font-size: 24px;
      font-weight: 500;
      word-break: keep-all;
      @media screen and (max-width: 1150px) {
        font-size: 20px;
      }
      @media screen and (max-width: 650px) {
        font-size: 16px;
      }
      @media screen and (max-width: 400px) {
        font-size: 14px;
      }

      span {
        @media screen and (max-width: 1100px) {
          display: block;
        }
      }
    }
  }
`
export const Article03Item = styled.ul`
  max-width: 1480px;
  padding: 0 40px;
  box-sizing: border-box;
  display: flex;
  @media screen and (max-width: 1150px) {
    display: block;
    padding: 0;
  }

  li {
    width: 33.3%;
    text-align: left;
    padding-top: 120px;
    @media screen and (max-width: 1150px) {
      width: 100%;
      padding-top: 0px;
      margin-top: 70px;
    }

    &:first-of-type {
      @media screen and (max-width: 650px) {
        margin-top: 40px;
      }
    }

    &:nth-of-type(1) h4:before {
      content: "01";
    }

    &:nth-of-type(2) h4:before {
      content: "02";
    }

    &:nth-of-type(3) h4:before {
      content: "03";
    }

    h4 {
      color: #222;
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 25px;
      position: relative;
      word-break: keep-all;
      line-height: 1.3;
      @media screen and (max-width: 1150px) {
        font-size: 26px;
        padding-left: 130px;
        margin-bottom: 15px;
      }
      @media screen and (max-width: 650px) {
        font-size: 24px;
        padding-left: 80px;
        margin-bottom: 10px;
      }
      @media screen and (max-width: 400px) {
        font-size: 22px;
      }

      &:before {
        display: inline-block;
        position: absolute;
        left: 0;
        top: 45px;
        color: #bcd330;
        font-size: 100px;
        font-weight: 900;
        opacity: 0.2;
        transform: translateY(-100%);
        z-index: -1;
        @media screen and (max-width: 1150px) {
          transform: translateY(-15%);
          top: 0;
          font-size: 80px;
        }
        @media screen and (max-width: 650px) {
          font-size: 60px;
        }
        @media screen and (max-width: 400px) {
          font-size: 50px;
        }
      }

      span {
        display: block;
      }
    }

    p {
      color: #838383;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.4;
      word-break: keep-all;
      @media screen and (max-width: 1150px) {
        font-size: 18px;
        padding-left: 130px;
      }
      @media screen and (max-width: 650px) {
        font-size: 16px;
        padding-left: 80px;
      }
      @media screen and (max-width: 400px) {
        font-size: 14px;
      }

      span {
        display: block;
        @media screen and (max-width: 650px) {
         display: inline;
        }
      }
    }
  }
`
export const Article04 = styled.article`
  background-color: #1b1f25;

  .article04Inner {
    max-width: 1200px;
    padding: 110px 100px 90px;
    box-sizing: border-box;
    margin: 0 auto;
    @media screen and (max-width: 1300px) {
      max-width: unset;
      width: 100%;
      padding: 110px 40px 90px;
    }
    @media screen and (max-width: 980px) {
      padding: 120px 40px 130px;
    }
    @media screen and (max-width: 480px) {
      padding: 90px 30px 110px;
    }
  }

  .article04Top {
    margin-bottom: 60px;
    @media screen and (max-width: 580px) {
      margin-bottom: 50px;
    }
    h3 {
      color: #bcd330;
      font-size: 42px;
      font-weight: 600;
      margin-bottom: 10px;
      line-height: 1.3;
      @media screen and (max-width: 980px) {
        font-size: 40px;
      }
      @media screen and (max-width: 580px) {
        font-size: 34px;
      }
    }

    p {
      color: #dbdbdb;
      font-size: 24px;
      font-weight: 600;
      word-break: keep-all;
      line-height: 1.4;
      @media screen and (max-width: 980px) {
        font-size: 22px;
      }
      @media screen and (max-width: 580px) {
        font-size: 16px;
      }
      @media screen and (max-width: 370px) {
        font-size: 14px;
      }
    }
  }

  .article04Form {
    font-size: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    & > div {
      display: flex;
      width: 48%;
      align-items: center;
      box-sizing: border-box;
      margin-bottom: 25px;
      @media screen and (max-width: 980px) {
        display: inline-block;
        width: 100%;
      }
      label {
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        width: 25%;
        position: relative;
        padding-left: 15px;
        display: inline-block;
        @media screen and (max-width: 1000px) {
          margin-bottom: 5px;
        }
        @media screen and (max-width: 370px) {
          font-size: 16px;
        }
        &:before {
          content: "";
          display: inline-block;
          width: 4px;
          height: 4px;
          background-color: #bcd330;
          position: absolute;
          left: 0;
          top: 50%;
          border-radius: 50%;
          transform: translateY(-50%);
        }
      }

      input[type="text"] {
        width: 75%;
        display: inline-block;
        border: 0;
        border-bottom: 1px solid #dcdcdc;
        background-color: transparent;
        font-size: 15px;
        font-weight: 300;
        color: #dbdbdb;
        height: 45px;
        @media screen and (max-width: 980px) {
          width: 100%;
        }
      
        @media screen and (max-width: 370px) {
          font-size: 14px;
        }
        &::placeholder {
          font-size: 15px;
          font-weight: 300;
          color: #dbdbdb;
        }
      }
    }

    textarea {
      margin-top: 45px;
      width: 100%;
      resize: none;
      border-bottom: 1px solid #dcdcdc;
      background-color: transparent;
      font-size: 15px;
      font-weight: 300;
      color: #dbdbdb;
      padding: 15px;
      box-sizing: border-box;
      @media screen and (max-width: 1000px) {
        margin-top: 0px;
      }
      &::placeholder {
        font-size: 15px;
        font-weight: 300;
        color: #dbdbdb;
      }
    }
  }

  .checkForm {
    display: flex;
    align-items: center;
    margin-top: 15px;
    @media screen and (max-width: 580px) {
     display: block;
    }
    input[type="checkbox"] {
      -webkit-appearance: none;
      position: relative;
      width: 20px;
      height: 20px;
      cursor: pointer;
      outline: none !important;
      border: 0;
      background: #fbfbfb;
      margin: 0 10px 0 0 !important;
    }

    input[type="checkbox"]::before {
      content: "\\2713";
      font-size: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      overflow: hidden;
      transform: scale(0) translate(-50%, -50%);
      line-height: 1;
    }

    input[type="checkbox"]:hover {
      border-color: rgba(170, 170, 170, 0.5);
    }

    input[type="checkbox"]:checked {
      background-color: #bcd330;
      color: #222;
    }

    input[type="checkbox"]:checked::before {
      transform: scale(1) translate(-50%, -50%)
    }

    label {
      color: #dbdbdb;
      font-size: 15px;
      font-weight: 300;
      margin-right: 10px;
      display: inline-flex;
      align-items: center;

      span {
        display: inline-block;
        margin-top: 3px;
      }
    }

    button {
      background-color: transparent;
      border: 0;
      color: #dbdbdb;
      font-size: 15px;
      font-weight: 300;
      cursor: pointer;
      margin-top: 2px;
      @media screen and (max-width: 580px) {
        display: block;
        margin-top: 5px;
        margin-left: 25px;
      }
    }
  }

  .submitBtn {
    display: block;
    height: 60px;
    line-height: 60px;
    width: 215px;
    border: 1px solid #fff;
    background-color: transparent;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    border-radius: 30px;
    padding: 0 25px;
    text-align: left;
    box-sizing: border-box;
    background-image: url("/img/icon/ico-btnRightArrow.png");
    background-repeat: no-repeat;
    background-position: 180px center;
    cursor: pointer;
    margin: 45px auto 0;
    @media screen and (max-width: 580px) {
      margin: 90px auto 0;
    }
    &:hover {
      background-image: url("/img/icon/ico-btnRightArrow-g.png");
      color: #bcd330;
      border-color: #bcd330;
      transition: 0.3s;
    }
  }
`