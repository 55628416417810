export const menuData = [
        {id:1, title:"회사소개", link:"/intro", arr:[{id:1, title:"소개", link:"/intro"}]},
        {id:2, title:"사업분야", link:"/business/Email",
            arr:[
                {id:1, title:"이메일 보안", link:"/business/email"},
                {id:2, title:"그룹웨어", link:"/business/groupware"},
                {id:3, title:"ERP", link:"/business/erp"},
                {id:4, title:"SI", link:"/business/si"}
            ]},
        {id:3, title:"소식", link:"/news/news",
            arr:[
                {id:1, title:"IR", link:"/news/ir"},
                {id:2, title:"뉴스", link:"/news/news"},
                {id:3, title:"보안 이슈", link:"/news/security-issue"},
                {id:4, title:"뉴스레터", link:"/news/news-letter"},
            ]},
        {id:4, title:"채용", link:"/recruit",
            arr:[
                {id:1, title:"채용", link:"/recruit"}
            ]},
]