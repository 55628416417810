import styled, {keyframes} from "styled-components"

const articleAni1 = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`

const articleAni2 = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
export const MaxWidth = styled.div`
  max-width: 1480px;
  padding: 0 40px;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 530px) {
    max-width: 1460px;
    padding: 0 30px;
  }
`
export const Banner = styled.div`
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 100px;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 100px;
  box-sizing: border-box;
  background-image: url("/img/img-banner05.png");

  h2 {
    color: #fff;
    font-size: 60px;
    font-weight: 700;
    padding: 340px 0 90px;
    line-height: 1.4;
    animation: ${articleAni2} 2s;
    @media screen and (max-width: 840px) {
      padding: 255px 0 45px;
      font-size: 44px;
    }
    @media screen and (max-width: 640px) {
      padding: 270px 0 30px;
      font-size: 32px;
    }

    span {
      @media screen and (max-width: 640px) {
        display: block;
      }
    }

    .fc-g {
      display: block;
      color: #bcd330;
    }
  }

  @media screen and (max-width: 1300px) {
    padding: 0 40px;
  }
  @media screen and (max-width: 840px) {
    background-position: -300px;
  }
  @media screen and (max-width: 530px) {
    padding: 0 30px;
    background-position: -550px;
  }
`
export const Article01 = styled.article`
  margin: 100px 0;
  animation: ${articleAni1} 2s;
  @media screen and (max-width: 1100px) {
    margin: 50px 0 80px;
  }
  @media screen and (max-width: 530px) {
    margin: 35px 0 50px;
  }

  & > div {
    display: flex;
    @media screen and (max-width: 1100px) {
      display: block;
    }
  }

  h3 {
    font-size: 42px;
    font-weight: 700;
    color: #222;
    width: 40%;
    line-height: 1.2;
    @media screen and (max-width: 1100px) {
      width: 100%;
      margin-bottom: 30px;
      font-size: 34px;
    }
    @media screen and (max-width: 530px) {
      font-size: 30px;
    }

    span {
      display: block;
      word-break: keep-all;

      &:last-of-type {
        font-weight: 500;
        font-size: 22px;
        @media screen and (max-width: 1100px) {
          font-size: 18px;
        }
        @media screen and (max-width: 530px) {
          font-size: 16px;
        }
      }
    }
  }

  p {
    width: 60%;
    @media screen and (max-width: 1100px) {
      width: 100%;
    }

    span {
      color: #636363;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.3;
      display: block;
      word-break: keep-all;
      @media screen and (max-width: 1100px) {
        font-size: 16px;
      }

      &:nth-of-type(1), &:nth-of-type(3) {
        margin-bottom: 20px;
      }
    }
  }
`
export const Article02 = styled.article`
  width: 100%;
  background-image: url("/img/img-si01.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 130px 0;
  animation: ${articleAni1} 2s;
  @media screen and (max-width: 1150px) {
    padding: 90px 0 120px;
  }
  @media screen and (max-width: 480px) {
    padding: 80px 0 100px;
  }

  .article02__inner {
    max-width: 1480px;
    padding: 0 40px;
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto;
    @media screen and (max-width: 480px) {
      padding: 0 30px;
    }

    h3 {
      color: #fff;
      font-size: 54px;
      font-weight: 500;
      text-align: center;
      line-height: 1.3;
      word-break: keep-all;
      @media screen and (max-width: 1150px) {
        font-size: 44px;
      }
      @media screen and (max-width: 530px) {
        font-size: 32px;
      }
      @media screen and (max-width: 400px) {
        font-size: 29px;
      }
      @media screen and (max-width: 370px) {
        font-size: 26px;
      }

      span {
        display: block;
        color: #8d97a3;
        font-size: 24px;
        font-weight: 500;
        word-break: keep-all;
        @media screen and (max-width: 1150px) {
          font-size: 22px;
        }
        @media screen and (max-width: 530px) {
          font-size: 16px;
        }
        @media screen and (max-width: 400px) {
          font-size: 15px;
        }
        @media screen and (max-width: 370px) {
          font-size: 14px;
        }
      }
    }
  }
`
export const Article02Item = styled.ul`
  display: flex;
  margin-top: 80px;
  @media screen and (max-width: 1150px) {
    display: block;
  }

  li {
    width: 33.3%;
    @media screen and (max-width: 1150px) {
      width: 100%;
      margin-bottom: 100px;
    }
    &:nth-of-type(1) h4{
      background-image: url("/img/ico-siItem01.png");
    }
    &:nth-of-type(2) h4{
      background-image: url("/img/ico-siItem02.png");
    }
    &:nth-of-type(3) h4{
      background-image: url("/img/ico-siItem03.png");
      @media screen and (max-width: 1150px) {
        margin-bottom: 0px;
      }
    }
    h4 {
      font-size: 30px;
      font-weight: 600;
      color: #fff;
      text-align: center;
      line-height: 1.3;
      padding-top: 100px;
      background-repeat: no-repeat;
      background-position: top center;
      @media screen and (max-width: 1300px) {
        font-size: 26px;
      }
      @media screen and (max-width: 1150px) {
        font-size: 30px;
      }
      @media screen and (max-width: 480px) {
        font-size: 24px;
      }
      @media screen and (max-width: 370px) {
        font-size: 20px;
      }
      span {
        display: block;
      }
    }

    p {
      color: #fff;
      font-size: 18px;
      font-weight: 200;
      text-align: center;
      line-height: 1.5;
      margin-top: 20px;
      @media screen and (max-width: 1300px) {
        font-size: 16px;
      }
      @media screen and (max-width: 1150px) {
        font-size: 18px;
      }
      @media screen and (max-width: 480px) {
        font-size: 14px;
      }
      @media screen and (max-width: 370px) {
        font-size: 13px;
      }

      span {
        display: block;
      }
    }
  }
`
export const Article03 = styled.article`
  margin: 140px 0 70px 260px;
  @media screen and (min-width: 2000px) {
    margin: 140px auto 190px;
    max-width: 1480px;
  }
  @media screen and (max-width: 1300px) {
    margin: 140px 0 70px 40px;
  }
  @media screen and (max-width: 1000px) {
    margin: 80px 0 70px 40px;
  }
  @media screen and (max-width: 530px) {
    margin: 80px 0 70px 30px;
  }
  .article03Top{
    padding-bottom: 80px;
    border-bottom: 2px solid #222;
    margin-bottom: 45px;
    @media screen and (max-width: 1000px) {
      padding-bottom: 50px;
      margin-bottom: 50px;
    }
  }

  h3{
    color: #222;
    font-size: 54px;
    font-weight: 600;
    margin-bottom: 15px;
    line-height: 1.3;
    @media screen and (max-width: 1000px) {
      font-size: 44px;
      margin-bottom: 10px;
    }
    @media screen and (max-width: 530px) {
        font-size: 30px;
    }
    @media screen and (max-width: 340px) {
      font-size: 27px;
    }
    span{
      @media screen and (max-width: 1000px) {
        display: block;
      }
    }
  }
  p{
    color: #222;
    font-size: 24px;
    font-weight: 500;
    @media screen and (max-width: 1000px) {
      font-size: 20px;
    }
    @media screen and (max-width: 530px) {
      font-size: 16px;
    }
    @media screen and (max-width: 340px) {
      font-size: 14px;
    }
  }
  ul{
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    max-width: 1400px;
    margin-right: 40px;
    box-sizing: border-box;
    font-size: 0;
    @media screen and (max-width: 1000px) {
      width: 100%;
      margin-right: 0;
    }
    li{
      width: 24%;
      height: 180px;
      border: 1px solid #ccc;
      margin-right: 1%;
      margin-bottom: 20px;
      box-sizing: border-box;
      background-color: #fff;
      img{
        max-width: calc(100% - 30px);
        margin: 0 15px;
      }
      a{
        display: inline-block;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &:nth-of-type(2n){
        @media screen and (max-width: 1000px) {
          margin-left: 2%;
        }
      }
      @media screen and (max-width: 1000px) {
        width: 48%;
        margin: 0 0 20px;
      }
      @media screen and (max-width: 530px) {
        height: 100px;
      }
    }
  }
`
export const BtnWrap = styled.div`
  margin-bottom: 200px;
  @media screen and (max-width: 1000px) {
    margin-bottom: 150px;
  }
  @media screen and (max-width: 530px) {
    margin-bottom: 100px;
  }
`
export const MoreBtn = styled.button`
  width: 55px;
  height: 55px;
  border: 1px solid #dedede;
  border-radius: 50%;
  background-color: #fff;
  display: block;
  margin: 0 auto;
  font-size: 0;
  background-image: url("/img/ico-si-arrow-g.png");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  transition: 0.5s;
  &.active{
    transform: rotate(180deg);
    background-color: #bcd330;
    border-color:#bcd330;
    background-image: url("/img/ico-si-arrow-w.png");
  }
  &:hover,&:active{
    background-color: #bcd330;
    border-color:#bcd330;
    background-image: url("/img/ico-si-arrow-w.png");
  }
`
export const Article04 = styled.article`
  background-color: #1b1f25;

  .article04Inner {
    max-width: 1200px;
    padding: 110px 100px 90px;
    box-sizing: border-box;
    margin: 0 auto;
    @media screen and (max-width: 1300px) {
      max-width: unset;
      width: 100%;
      padding: 110px 40px 90px;
    }
    @media screen and (max-width: 980px) {
      padding: 120px 40px 130px;
    }
    @media screen and (max-width: 530px) {
      padding: 90px 30px 110px;
    }
  }

  .article04Top {
    margin-bottom: 60px;
    @media screen and (max-width: 580px) {
      margin-bottom: 50px;
    }
    h3 {
      color: #bcd330;
      font-size: 42px;
      font-weight: 600;
      margin-bottom: 10px;
      line-height: 1.3;
      @media screen and (max-width: 980px) {
        font-size: 40px;
      }
      @media screen and (max-width: 580px) {
        font-size: 34px;
      }
    }

    p {
      color: #dbdbdb;
      font-size: 24px;
      font-weight: 600;
      word-break: keep-all;
      line-height: 1.4;
      @media screen and (max-width: 980px) {
        font-size: 22px;
      }
      @media screen and (max-width: 580px) {
        font-size: 16px;
      }
      @media screen and (max-width: 370px) {
        font-size: 14px;
      }
    }
  }

  .article04Form {
    font-size: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    & > div {
      display: flex;
      width: 48%;
      align-items: center;
      box-sizing: border-box;
      margin-bottom: 25px;
      @media screen and (max-width: 980px) {
        display: inline-block;
        width: 100%;
      }
      label {
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        width: 25%;
        position: relative;
        padding-left: 15px;
        display: inline-block;
        @media screen and (max-width: 1000px) {
          margin-bottom: 5px;
        }
        @media screen and (max-width: 370px) {
          font-size: 16px;
        }
        &:before {
          content: "";
          display: inline-block;
          width: 4px;
          height: 4px;
          background-color: #bcd330;
          position: absolute;
          left: 0;
          top: 50%;
          border-radius: 50%;
          transform: translateY(-50%);
        }
      }

      input[type="text"] {
        width: 75%;
        display: inline-block;
        border: 0;
        border-bottom: 1px solid #dcdcdc;
        background-color: transparent;
        font-size: 15px;
        font-weight: 300;
        color: #dbdbdb;
        height: 45px;
        @media screen and (max-width: 980px) {
          width: 100%;
        }

        @media screen and (max-width: 370px) {
          font-size: 14px;
        }
        &::placeholder {
          font-size: 15px;
          font-weight: 300;
          color: #dbdbdb;
        }
      }
    }

    textarea {
      margin-top: 45px;
      width: 100%;
      resize: none;
      border-bottom: 1px solid #dcdcdc;
      background-color: transparent;
      font-size: 15px;
      font-weight: 300;
      color: #dbdbdb;
      padding: 15px;
      box-sizing: border-box;
      @media screen and (max-width: 1000px) {
        margin-top: 0px;
      }
      &::placeholder {
        font-size: 15px;
        font-weight: 300;
        color: #dbdbdb;
      }
    }
  }

  .checkForm {
    display: flex;
    align-items: center;
    margin-top: 15px;
    @media screen and (max-width: 580px) {
      display: block;
    }
    input[type="checkbox"] {
      -webkit-appearance: none;
      position: relative;
      width: 20px;
      height: 20px;
      cursor: pointer;
      outline: none !important;
      border: 0;
      background: #fbfbfb;
      margin: 0 10px 0 0 !important;
    }

    input[type="checkbox"]::before {
      content: "\\2713";
      font-size: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      overflow: hidden;
      transform: scale(0) translate(-50%, -50%);
      line-height: 1;
    }

    input[type="checkbox"]:hover {
      border-color: rgba(170, 170, 170, 0.5);
    }

    input[type="checkbox"]:checked {
      background-color: #bcd330;
      color: #222;
    }

    input[type="checkbox"]:checked::before {
      transform: scale(1) translate(-50%, -50%)
    }

    label {
      color: #dbdbdb;
      font-size: 15px;
      font-weight: 300;
      margin-right: 10px;
      display: inline-flex;
      align-items: center;

      span {
        display: inline-block;
        margin-top: 3px;
      }
    }

    button {
      background-color: transparent;
      border: 0;
      color: #dbdbdb;
      font-size: 15px;
      font-weight: 300;
      cursor: pointer;
      margin-top: 2px;
      @media screen and (max-width: 580px) {
        display: block;
        margin-top: 5px;
        margin-left: 25px;
      }
    }
  }

  .submitBtn {
    display: block;
    height: 60px;
    line-height: 60px;
    width: 215px;
    border: 1px solid #fff;
    background-color: transparent;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    border-radius: 30px;
    padding: 0 25px;
    text-align: left;
    box-sizing: border-box;
    background-image: url("/img/icon/ico-btnRightArrow.png");
    background-repeat: no-repeat;
    background-position: 180px center;
    cursor: pointer;
    margin: 45px auto 0;
    @media screen and (max-width: 580px) {
      margin: 90px auto 0;
    }
    &:hover {
      background-image: url("/img/icon/ico-btnRightArrow-g.png");
      color: #bcd330;
      border-color: #bcd330;
      transition: 0.3s;
    }
  }
`