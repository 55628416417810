import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
    Article01, Article02,
    Article03,
    Article03Con,
    Article03ConInner,
    Article04,
    Controller,
    COntrollerBot,
    Article05,
    BtnGroup, Article05Top, SlideItem, Section
} from "./style";
import {Swiper, SwiperSlide} from 'swiper/react';
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import {Navigation, Mousewheel, Scrollbar, A11y, Pagination, EffectFade} from 'swiper';
import 'swiper/css';
import 'swiper/css/scrollbar';
import {Link} from "react-router-dom";
import axios from "axios";
import LazyImage from "../hooks/useLazeImg";
import EventPopUp from "../popup/EventPopUp";


const Main = () => {
    const slideRef = useRef(null);
    const videoRef = useRef(null);
    const nowSlide = useRef(null);
    const lastRef = useRef(null);

    const subSlideRef1 = useRef(null);
    const subSlideRef2 = useRef(null);
    const subSlideRef3 = useRef(null);
    const subSlideRef4 = useRef(null);

    const slideMenuRef1 = useRef(null);
    const slideMenuRef2 = useRef(null);
    const slideMenuRef3 = useRef(null);
    const slideMenuRef4 = useRef(null);

    const [nowPlaying, setNowPlaying] = useState(true);
    const [news, setNews] = useState();
    const [swiper, setSwiper] = useState();
    const [subSwiper, setSubSwiper] = useState();
    const [subSlideNow, setSubSlideNow] = useState(1);

    /*이벤트 팝업*/
    const [closePop, setClosePop] = useState(true);
    const [todayPop, setTodayPop] = useState(false);

    const onChangeToday = useCallback((checked) => {
        setTodayPop(checked)
    },[todayPop, setTodayPop]);

    const VISITED_BEFORE_DATE = localStorage.getItem('VisitCookie')

    const VISITED_NOW_DATE = Math.floor(new Date().getDate())

    useEffect(() => {
        // 팝업 오늘 하루닫기 체크
        if (VISITED_BEFORE_DATE !== null) {
            // 날짜가 같을경우 노출
            if (VISITED_BEFORE_DATE === VISITED_NOW_DATE) {
                localStorage.removeItem('VisitCookie')
                setClosePop(true)
            }
            // 날짜가 다를경우 비노출
            if (VISITED_BEFORE_DATE !== VISITED_NOW_DATE) {
                setClosePop(false)
            }
        }
    }, [VISITED_BEFORE_DATE])


    const onClickPop = useCallback(() => {
        if(todayPop){
            const expiry = new Date()
            // +1일 계산
            const expiryDate = expiry.getDate() + 1
            // 로컬스토리지 저장
            localStorage.setItem('VisitCookie', expiryDate)
        }
        setClosePop(false);
    },[closePop, todayPop]);

    const toggleBtn = useCallback(() => {
        setNowPlaying(prev => !prev);
        nowPlaying ? videoRef.current.pause() : videoRef.current.play()
    }, [videoRef, nowPlaying])

    /*LINE::슬라이드 옵션*/
    let set = {
        direction: "vertical",
        slidesPerView: 1,
        spaceBetween: 0,
        mousewheel: true,
        modules: [Mousewheel],
        className: "swiperSection",
        observer: true,
        allowTouchMove: true,
        breakpoints: {
            1500: {
                allowTouchMove: false
            },
            300: {
                allowTouchMove: true
            }
        }
    }

    let set2 = {
        direction: "vertical",
        slidesPerView: 1,
        spaceBetween: 0,
        mousewheel: true,
        modules: [Mousewheel, EffectFade, Pagination, Navigation],
        className: "swiperSection",
        pagination:{
            type: "fraction"
        },
        navigation:true,
        observer: true,
        allowTouchMove: true,
        breakpoints: {
            1500: {
                allowTouchMove: false
            },
            300: {
                allowTouchMove: true,
                mousewheel: false,
            }
        }
    }

    let botChg = 0;
    window.addEventListener('wheel', (e) => {
        const max = window.matchMedia("screen and (max-width: 1500px)");
        if (!max.matches) {
            if (swiper && slideRef.current.classList.contains("swiper-slide-active")) {
                swiper?.mousewheel?.disable();
                if (e.deltaY <= -100 && subSlideRef1.current.classList.contains("swiper-slide-active")) {
                    swiper?.mousewheel?.enable();
                }else if(e.deltaY >= 100 && subSlideRef4.current.classList.contains("swiper-slide-active")){
                    swiper?.mousewheel?.enable();
                }
            }

            if(window.location.pathname === "/"){
                if (swiper && lastRef.current.classList.contains("swiper-slide-active") && e.deltaY >= 100) {
                    botChg++;
                    if (botChg >= 2) {
                        swiper?.mousewheel?.disable();
                        botChg = 0;
                        window.scrollTo({
                            top: 300,
                            behavior: "smooth"
                        });
                    }
                } else if(window.scrollY === 300 & e.deltaY <= -100) {
                    swiper?.mousewheel?.enable();
                    botChg = 0;
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                }else if(swiper && !lastRef.current.classList.contains("swiper-slide-active")){
                    botChg = 0;
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                }
            }
        }else{
            swiper?.mousewheel?.enable();
        }


    })


    useEffect(() => {
        if (subSlideRef1.current.classList.contains("swiper-slide-active")) {
            slideMenuRef1.current.classList.add("active")
            slideMenuRef2.current.classList.remove("active")
            slideMenuRef3.current.classList.remove("active")
            slideMenuRef4.current.classList.remove("active")
            nowSlide.current.style.top = "7%"
            swiper?.mousewheel?.disable();
        }else if (subSlideRef2.current.classList.contains("swiper-slide-active")) {
            slideMenuRef1.current.classList.remove("active")
            slideMenuRef2.current.classList.add("active")
            slideMenuRef3.current.classList.remove("active")
            slideMenuRef4.current.classList.remove("active")
            nowSlide.current.style.top = "32%"
            swiper?.mousewheel?.disable();
        }else if(subSlideRef3.current.classList.contains("swiper-slide-active")){
            slideMenuRef1.current.classList.remove("active")
            slideMenuRef2.current.classList.remove("active")
            slideMenuRef3.current.classList.add("active")
            slideMenuRef4.current.classList.remove("active")
            nowSlide.current.style.top = "57%"
            swiper?.mousewheel?.disable();
        }else if(subSlideRef4.current.classList.contains("swiper-slide-active")){
            slideMenuRef1.current.classList.remove("active")
            slideMenuRef2.current.classList.remove("active")
            slideMenuRef3.current.classList.remove("active")
            slideMenuRef4.current.classList.add("active")
            nowSlide.current.style.top = "82%"
            swiper?.mousewheel?.disable();
        }
    },[subSlideNow]);

    const onTop = () => {
        swiper?.slideTo(0);
        swiper?.mousewheel?.enable();
        botChg = 0;
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        if(window.location.pathname === "/"){
            document.querySelector('body').style.overflow = "hidden"
        }

        axios.get("https://apis.kiwontech.com/board/news?orderBy=main")
            .then(res => setNews(res.data.list))
    }, [])

    return (
        <Section>
            <Swiper
                {...set} onSwiper={setSwiper}
            >
                <SwiperSlide className="art01">
                    <Article01 id="scrollPage">
                        <video src="/video/mainVideo.mp4" type="video/mp4" muted autoPlay="autoplay" loop="loop"
                               width="100%"
                               ref={videoRef}></video>
                        <div className="article01MainCon">
                            <h2>
                                <span>KIWONTECH <span>guides you to Innovation</span></span>
                                <span>미래로 나아가는 <span>선도기업, <b>기원테크</b></span></span>
                                <span>“ IT업계의 리더로서, 혁신을 창조합니다.”</span>
                            </h2>
                            <div>
                                <button type="button" className={"videoBtn" + (nowPlaying ? "" : " stop")}
                                        onClick={toggleBtn}>
                                    {
                                        nowPlaying ?
                                            <div>
                                                <img src="/img/img-stop.png" className="white" alt="동영상 정지"/>
                                                <img src="/img/img-stop_g.png" className="color" alt="동영상 정지"/>
                                            </div> :
                                            <img src="/img/img-play.png" alt="동영상 재생"/>

                                    }
                                </button>
                                <Link to="/intro" className="moreBtn" title="소개페이지 바로가기">
                                    소개 바로가기
                                </Link>
                            </div>
                        </div>
                        <div className="scroll-arrow">
                            <img src="/img/img-scroll-arrow.png" alt="아래로 스크롤이동"/>
                        </div>
                    </Article01>
                </SwiperSlide>
                <SwiperSlide className="art02">
                    <Article02>
                        <div>
                            <p className="overlap">
                                <span>30년의 오랜 경험을 가진 <span>기원테크는</span></span>
                                <span>스마트하고 안전한 <span>업무환경을 제공하기 위해</span></span>
                                <span>IT Solution 개발에 끊임없이 도전,</span>
                                <span>그리고 성장해 왔습니다.</span>
                            </p>
                        </div>
                        <div className="scroll-arrow">
                            <img src="/img/img-scroll-arrow.png" alt="아래로 스크롤이동"/>
                        </div>
                    </Article02>
                </SwiperSlide>
                <SwiperSlide className="art03" ref={slideRef}>
                    <Article03>
                        <Controller>
                            <ul>
                                <li ref={slideMenuRef1} className="active">
                                    <button type="button" onClick={() => subSwiper.slideTo(0)}>1994</button>
                                </li>
                                <li ref={slideMenuRef2}>
                                    <button type="button" onClick={() => subSwiper.slideTo(1)}>2002</button>
                                </li>
                                <li ref={slideMenuRef3}>
                                    <button type="button" onClick={() => subSwiper.slideTo(2)}>2014</button>
                                </li>
                                <li ref={slideMenuRef4}>
                                    <button type="button" onClick={() => subSwiper.slideTo(3)}>2021</button>
                                </li>
                            </ul>
                            <div ref={nowSlide}></div>
                        </Controller>
                        <Swiper
                            {...set2} onSwiper={setSubSwiper} effect={"fade"} onSlideChange={() => setSubSlideNow(subSlideNow + 1)}
                        >
                            <SwiperSlide ref={subSlideRef1}>
                                <Article03Con>
                                    <Article03ConInner>
                                        <div>
                                            <h2 className="txtAni1">
                                                <span>1994</span>
                                                <span>IT Solution의 첫 도약,</span>
                                                <span>ERP 프로그램 시작</span>
                                            </h2>
                                            <h3 className="txtAni1">혁신을 창조하는 기원테크 설립</h3>
                                            <span className="txtAni2">
                                                기업 업무의 시작부터 끝까지 전과정을 체계적으로<br/>
                                                관리할 수 있는 기업용 전사적 자원관리 프로그램
                                            </span>
                                        </div>
                                        <div>
                                            <div>
                                                <div>
                                                    <img src="/img/img-main03-con1.png" className="main01"
                                                         alt="1994 ERO 프로그램 시작"/>
                                                    <img src="/img/img-main03-con1_2.png" className="main01_2"/>
                                                </div>

                                            </div>
                                        </div>
                                    </Article03ConInner>
                                </Article03Con>
                            </SwiperSlide>
                            <SwiperSlide ref={subSlideRef2}>
                                <Article03Con>
                                    <Article03ConInner>
                                        <div>
                                            <h2 className="txtAni1">
                                                <span>2002</span>
                                                <span>경쟁력 있는 IT기업으로의 성장,</span>
                                                <span>SI 시스템 개발</span>
                                            </h2>
                                            <h3 className="txtAni1">시스템 설계부터 개발까지, <span>기업 맞춤형 솔루션</span></h3>
                                            <span className="txtAni2">
                                                ERP의 경험으로 전문적인 컨설팅을 통해<br/>
                                                고객의 Needs를 반영한, 맞춤형 통합관리 시스템 SI 솔루션
                                            </span>
                                        </div>
                                        <div>
                                            <div>
                                                <div>
                                                    <img src="/img/img-main03-con2.png" className="main02"
                                                         alt="2002 경쟁력 있는 IT기업으로의 성장"/>
                                                    <img src="/img/img-main03-con2_2.png" className="main02_2"/>
                                                </div>
                                            </div>
                                        </div>
                                    </Article03ConInner>
                                </Article03Con>
                            </SwiperSlide>
                            <SwiperSlide ref={subSlideRef3}>
                                <Article03Con>
                                    <Article03ConInner className="slideNo3">
                                        <div>
                                            <h2 className="txtAni1">
                                                <span>2014</span>
                                                <span>이메일 보안 솔루션의 혁신,</span>
                                                <span>EG-Platform 개발</span>
                                            </h2>
                                            <h3 className="txtAni1">국내 유일 이메일 보안 전문가</h3>
                                            <span className="txtAni2">
                                                기업의 안전한 메일 사용을 위해 수신부터 발신까지 <br/>
                                                전구간을 보호하는 이메일 보안 솔루션
                                            </span>
                                        </div>
                                        <div>
                                            <div>
                                                <div>
                                                    <img src="/img/img-main03-con3.png" className="main03"
                                                         alt="2014 이메일 보안 솔루션의 혁신,"/>
                                                    <img src="/img/img-main03-con3_2.png" className="main03_2"/>
                                                </div>
                                            </div>
                                        </div>
                                    </Article03ConInner>
                                </Article03Con>
                            </SwiperSlide>
                            <SwiperSlide ref={subSlideRef4}>
                                <Article03Con>
                                    <Article03ConInner className="slideNo3">
                                        <div>
                                            <h2 className="txtAni1">
                                                <span>2021</span>
                                                <span>업무 협업 Tool 그룹웨어,</span>
                                                <span>SECU E Cloud 서비스 시작</span>
                                            </h2>
                                            <h3 className="txtAni1">기업의 성장을 돕는 효율적 업무 파트너</h3>
                                            <span className="txtAni2">
                                                EG-Platform에 커뮤니케이션 기능과 <span>체계적인 업무 협업 Tool을 더한</span>
                                                <span className="br">안전하고 편리한 All-in-One 그룹웨어</span>
                                            </span>
                                        </div>
                                        <div>
                                            <div>
                                                <div>
                                                    <img src="/img/img-main03-con4.png" className="main04"
                                                         alt="2021 SECU E Cloud 서비스 시작"/>
                                                    <img src="/img/img-main03-con4_2.png" className="main04_2"/>
                                                </div>
                                            </div>
                                        </div>
                                    </Article03ConInner>
                                </Article03Con>
                                <div className="scroll-arrow">
                                    <img src="/img/img-scroll-arrow.png" alt="아래로 스크롤이동"/>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </Article03>
                </SwiperSlide>
                <SwiperSlide className="act04">
                    <Article04>
                        <div className="Article04-inner">
                            <div>
                                <p className="txt">
                                    <span>수많은 도전과 경험을 토대로</span>
                                    <span>2022년 기원테크는</span>
                                    <span>국내 유일 All-in-One Email System을 보유한</span>
                                    <span>IT Solution 선도기업으로 성장하였습니다.</span>
                                </p>
                                <p className="mbtxt">
                                    <span>수많은 도전과 경험을 토대로</span>
                                    <span>2022년 기원테크는</span>
                                    <span>국내 유일 <span>All-in-One Email System을 보유한</span></span>
                                    <span>IT Solution 선도기업으로 <span>성장하였습니다.</span></span>
                                </p>
                            </div>
                            <span className="star star01"></span>
                            <span className="star star02"></span>
                            <span className="star star03"></span>
                            <span className="star star04"></span>
                            <span className="star star05"></span>
                            <span className="star star06"></span>
                            <span className="star star07"></span>
                            <span className="star star08"></span>
                            <span className="star star09"></span>
                            <span className="star star10"></span>
                            <span className="star star11"></span>
                            <span className="star star12"></span>
                            <span className="star star13"></span>
                            <span className="star star14"></span>
                            <span className="star star15"></span>
                            <span className="star star16"></span>
                        </div>
                        <div className="scroll-arrow">
                            <img src="/img/img-scroll-arrow.png" alt="아래로 스크롤이동"/>
                        </div>
                    </Article04>
                </SwiperSlide>
                <SwiperSlide ref={lastRef}>
                    <Article05>
                        <div>
                            <Article05Top>
                                <h3>NEWS</h3>
                                <BtnGroup>
                                    <button type="button" className="swiper-button-prev">
                                    </button>
                                    <button type="button" className="swiper-button-next">
                                    </button>
                                    <Link to="/news/news" title="NEWS 페이지 이동">
                                    </Link>
                                </BtnGroup>
                            </Article05Top>
                            <Swiper
                                // install Swiper modules
                                breakpoints={{
                                    1930: {
                                        slidesPerView: 5
                                    },
                                    1700: {
                                        slidesPerView: 4
                                    },
                                    1400: {
                                        slidesPerView: 3
                                    },
                                    1100: {
                                        slidesPerView: 2.5
                                    },
                                    450: {
                                        slidesPerView: 1.5

                                    },
                                    300: {
                                        slidesPerView: 1.2
                                    }
                                }}
                                modules={[Scrollbar, Navigation, A11y]}
                                spaceBetween={50}
                                scrollbar={{draggable: true}}
                                navigation={{
                                    nextEl: '.swiper-button-next', // 다음 버튼 클래스명
                                    prevEl: '.swiper-button-prev', // 이전 버튼 클래스명
                                }}

                            >
                                {
                                    news?.map(item =>
                                        <SwiperSlide key={item.idx}>
                                            <SlideItem>
                                                <Link to={`/news/news/${item.idx}`}>
                                                    <div className="imgWrap">
                                                        <LazyImage
                                                            src={`https://apis.kiwontech.com/file/download/${item.mainImgIdx}`}
                                                            alt={item.title}/>
                                                        />
                                                    </div>
                                                    <p>
                                                        {
                                                            item.title
                                                        }
                                                    </p>
                                                </Link>
                                            </SlideItem>
                                        </SwiperSlide>
                                    )
                                }
                            </Swiper>
                        </div>
                        <div className="scroll-arrow" onClick={() => onTop()}>
                            <img src="/img/img-scroll-arrow.png" alt="최상단 스크롤이동"/>
                        </div>
                    </Article05>
                </SwiperSlide>
            </Swiper>
{/*            {
                closePop && <EventPopUp onClickPop={onClickPop} onChangeToday={onChangeToday}/>
            }*/}
        </Section>
    );
};

export default Main;