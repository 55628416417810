import styled, {keyframes} from "styled-components"

export const MaxWidth = styled.div`
  max-width: 1280px;
  padding: 0 40px;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 530px) {
    max-width: 1460px;
    padding: 0 30px;
  }
`
export const ViewSection = styled.section`
  h2{
    color: #282828;
    font-size: 54px;
    font-weight: 700;
    margin: 200px 0 90px;
    @media screen and (max-width: 800px) {
      margin: 140px 0 40px;
      font-size: 40px;
    }
    @media screen and (max-width: 500px) {
      margin: 130px 0 40px;
      font-size: 40px;
    }
  }
  .viewTop{
    border-bottom: 1px solid #b9b9b9;
    padding-bottom: 20px;
    display: flex;
    align-items: end;
    justify-content: space-between;
    margin-bottom: 70px;
    &.mb-n {
        margin-bottom: 0;
    }  
    @media screen and (max-width: 800px) {
      display: block;
      padding-bottom: 10px;
      margin-bottom: 50px;
    }
    @media screen and (max-width: 500px) {
      margin-bottom: 30px;
    }
    h3{
      color: #282828;
      font-size: 30px;
      font-weight: 700;
      width: calc(100% - 100px);
      line-height: 1.3;
      word-break: keep-all;
      @media screen and (max-width: 800px) {
        font-size: 24px;
        width: 100%;
      }
      @media screen and (max-width: 500px) {
        font-size: 20px;
      }
    }
    span{
      color: #999;
      font-size: 16px;
      font-weight: 500;
      display: inline-block;
      width: 80px;
      margin-left: 20px;
      @media screen and (max-width: 800px) {
        margin-left: 0;
        width: 100%;
        margin-top: 20px;
      }
      @media screen and (max-width: 800px) {
        margin-top: 15px;
      }
    }
  }
  .fileAttach {
      padding: 15px 20px;
      border-radius: 4px;
      border: 1px solid rgb(185, 185, 185);
      width: 100%;
      margin: 15px 0 30px;
      box-sizing: border-box;
      & > .title {
          font-weight: 500;
      }
      .fileList {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          .fileItem {
              display: flex;
              align-content: center;
              gap: 10px;
              .fileName {
                  color: #333;
                  max-width: calc(100% - 30px);
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
              }
              .fileUrl {
                  width: 17px;
                  height: 15px;
                  background: no-repeat url("/img/ico-download.png");
                  cursor: pointer;
              }
          }
      }
  }  
  .content{
    padding-bottom: 90px;
    border-bottom: 2px solid #b9b9b9;
    word-break: keep-all;
    line-height: 1.4;
    @media screen and (max-width: 800px) {
      padding-bottom: 50px;
    }
    span,p,div{
      line-height: 1.4;
      word-break: keep-all;
    }
    table{
    margin: 0 auto;
      max-width: 100% !important;
      @media screen and (max-width: 800px) {
        max-width: 100% !important;
        width: auto !important;
      }
    }
    img{
      max-width: 100%;
    }
  }
  .listLink{
    border-top: 1px solid #dddddd;
    margin: 80px 0;
    @media screen and (max-width: 800px) {
      margin: 50px 0;
    }
    @media screen and (max-width: 500px) {
      margin: 40px 0 60px;
    }
    a{
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ddd;
      &.disabled{
        pointer-events: none;
      }
      &:first-of-type{
        span:first-of-type{
          background-image: url("/img/icon/ico-view-next.png");
        }
      }
      &:last-of-type{
        span:first-of-type{
          background-image: url("/img/icon/ico-view-prev.png");
        }
      }
      span{
        background-repeat: no-repeat;
        background-position: center right;
        display: inline-block;
        line-height: 1.3;
        color: #282828;
        font-weight: 400;
        font-size: 16px;
        padding: 20px 0;
        word-break: keep-all;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:first-of-type{
          width: 110px;
          text-align: center;
          margin-right: 40px;
        }
        &:last-of-type{
          width: calc(100% - 150px);
        }
      }
    }
  }
`
export const ListBtn = styled.button`
  width: 150px;
  height: 45px;
  background-color: #bcd330;
  border-radius: 25px;
  border: 1px solid #f3f3f3;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border: 0;
  display: block;
  margin: 0 auto 150px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
  cursor: pointer;
  @media screen and (max-width: 800px) {
   width: 100%;
    margin: 0 auto 100px;
  }
`