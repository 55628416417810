import styled, {keyframes} from "styled-components"

const listAni01 = keyframes`
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
`
const listAni02 = keyframes`
  0% {
    width: 0%;
  }
  100% {
    width: 80%;
  }
`
const listAni03 = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
export const MaxWidth = styled.div`
  max-width: 1480px;
  padding: 0 40px;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 530px) {
    max-width: 1460px;
    padding: 0 30px;
  }
`

export const H2 = styled.h2`
  color: #191919;
  font-size: 40px;
  font-weight: 700;
  border-bottom: 1px solid #ddd;
  padding-bottom: 90px;
  margin: 190px 0 140px;
  @media screen and (max-width: 1000px) {
    font-size: 28px;
    margin: 145px 0 110px;
    padding-bottom: 75px;
  }
  @media screen and (max-width: 560px) {
    line-height: 1.3;
    font-size: 26px;
    margin: 130px 0 70px;
    padding-bottom: 60px;
  }

  .mb-b {
    @media screen and (max-width: 560px) {
      display: block;
    }
  }

  .fc-g {
    font-size: 54px;
    @media screen and (max-width: 1000px) {
      font-size: 40px;
    }
    @media screen and (max-width: 560px) {
      font-size: 36px;
    }

    span {
      color: #bcd330;
    }
  }
`
export const Article01 = styled.article`
  h3 {
    color: #191919;
    font-size: 42px;
    font-weight: 800;
    max-width: 1480px;
    padding: 0 40px;
    box-sizing: border-box;
    margin: 0 auto 60px;
    @media screen and (max-width: 530px) {
      font-size: 32px;
      line-height: 1.3;
      padding: 0 30px;
    }

    span {
      color: #bcd330;
    }
  }

  .wrap {
    background-image: url("/img/img-banner06.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .inner {
    max-width: 1480px;
    padding: 140px 40px;
    box-sizing: border-box;
    margin: 0px auto;
    @media screen and (max-width: 1000px) {
      padding: 110px 40px;
    }
    @media screen and (max-width: 530px) {
      padding: 110px 30px 30px;
    }
    & > div {
      display: flex;
      box-sizing: border-box;
      position: relative;

      &:after {
        content: "";
        display: inline-block;
        position: absolute;
        height: 2px;
        background-color: #bcd330;
        top: 0;
        left: 0;
      }

      h2 {
        color: #fffefe;
        font-size: 32px;
        font-weight: 600;
        padding: 30px 0 20px;
        position: relative;
        @media screen and (max-width: 1200px) {
          font-size: 26px;
        }
        @media screen and (max-width: 530px) {
          border-top: 2px solid #bcd330;
          padding: 30px 0 15px;
          margin-right: 10px;
          &:after {
            content: "";
            display: inline-block;
            width: 10px!important;
            height: 10px!important;
            position: absolute;
            border: 2px solid #bcd330;
            border-radius: 50%;
            right: 0;
            top: 0;
            opacity: 1;
            animation-play-state: paused;
            transform: translate(100%, -50%);
          }
        }

        &:before {
          content: "";
          display: inline-block;
          width: 15px;
          height: 15px;
          position: absolute;
          background-color: #bcd330;
          border-radius: 50%;
          left: 0;
          top: 0;
          transform: translateY(-50%);
          @media screen and (max-width: 530px) {
            width: 10px!important;
            height: 10px!important;
            transform: translateY(-60%);
          }
        }

        span {
          color: #bcd330;
          font-size: 38px;
          font-weight: 900;
          @media screen and (max-width: 1200px) {
            font-size: 32px;
          }
        }
      }

      p {
        color: #dbdbdb;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.4;
        word-break: keep-all;
        @media screen and (max-width: 1200px) {
          font-size: 16px;
        }

        span {
          display: block;
        }
      }
    }
  }

  .list01 {
    position: relative;
    margin-bottom: 80px;
    width: 58%;
    @media screen and (max-width: 1000px) {
      width: 100%;
    }
    @media screen and (max-width: 530px) {
      flex-wrap: wrap;
      margin-bottom: 0;
    }

    & > div {
      position: relative;
      width: 50%;

      div {
        opacity: 0;
        @media screen and (max-width: 530px) {
          opacity: 1;
        }
      }
    }

    & > div {
      @media screen and (max-width: 530px) {
        width: 100%;
        margin-bottom: 80px;
      }

      &:nth-of-type(2) {
        h2 {
          &:after {
            content: "";
            display: inline-block;
            width: 15px;
            height: 15px;
            position: absolute;
            border: 2px solid #bcd330;
            border-radius: 50%;
            right: 0;
            top: 0;
            opacity: 0;
            animation: ${listAni03} 0.3s;
            animation-fill-mode: forwards;
            animation-delay: 1s;
            transform: translate(100%, -50%);

            @media screen and (max-width: 530px) {
              animation-play-state: paused;
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .list02 {
    margin-left: 29%;
    width: 73%;
    @media screen and (max-width: 1000px) {
      width: 100%;
      margin-left: 0;
      flex-wrap: wrap;
    }

    & > div {
      position: relative;
      width: 42.5%;
      @media screen and (max-width: 1000px) {
        width: 50%;
      }
      @media screen and (max-width: 530px) {
        width: 100%;
        margin-bottom: 80px;
      }

      div {
        opacity: 0;
        @media screen and (max-width: 530px) {
          opacity: 1;
        }
      }

      &:last-of-type {
        border-top: 0;
        width: 22%;
        @media screen and (max-width: 1000px) {
          width: 50%;
        }
        @media screen and (max-width: 530px) {
          width: 100%;
        }
      }
    }
  }

  .active {
    @media screen and (min-width: 530px) {
      .list01 {
        &:after {
          animation: ${listAni01} 1s;
          animation-fill-mode: forwards;
        }
      }

      .list02 {
        &:after {
          animation: ${listAni02} 1s;
          animation-delay: 1s;
          animation-fill-mode: forwards;
          @media screen and (max-width: 1000px) {
            animation: ${listAni01} 1.5s;
            animation-delay: 1.5s;
            animation-fill-mode: forwards;
          }
        }

        div:nth-of-type(2) h2 {
          @media screen and (max-width: 1000px) {
            &:after {
              content: "";
              display: inline-block;
              width: 15px;
              height: 15px;
              position: absolute;
              border: 2px solid #bcd330;
              border-radius: 50%;
              right: 0;
              top: 0;
              opacity: 0;
              animation: ${listAni03} 0.3s;
              animation-fill-mode: forwards;
              animation-delay: 3s;
              transform: translate(100%, -50%);
            }
          }
        }

        div:nth-of-type(3) {
          @media screen and (max-width: 1000px) {
            margin-top: 80px;
          }

          h2 {
            @media screen and (max-width: 1000px) {
              border-top: 2px solid #bcd330;
              &:after {
                content: "";
                display: inline-block;
                width: 15px;
                height: 15px;
                position: absolute;
                border: 2px solid #bcd330;
                border-radius: 50%;
                right: 0;
                top: 0;
                opacity: 0;
                animation: ${listAni03} 0.3s;
                animation-fill-mode: forwards;
                animation-delay: 3s;
                transform: translate(100%, -50%);
              }
            }
          }
        }
      }

      .ani01 {
        animation: ${listAni03} 0.5s;
        animation-fill-mode: forwards;
        opacity: 1;
      }

      .ani02 {
        animation: ${listAni03} 0.5s;
        animation-delay: 0.5s;
        animation-fill-mode: forwards;
      }

      .ani03 {
        animation: ${listAni03} 0.5s;
        animation-delay: 1.1s;
        animation-fill-mode: forwards;
      }

      .ani04 {
        animation: ${listAni03} 0.5s;
        animation-delay: 1.5s;
        animation-fill-mode: forwards;
      }

      .ani05 {
        animation: ${listAni03} 0.5s;
        animation-delay: 2s;
        animation-fill-mode: forwards;
      }
    }
  }

`
export const Article02 = styled.article`
  margin: 200px 0 170px;
  @media screen and (max-width: 1000px) {
    margin: 100px 0 60px;
  }

  .article02__top {
    display: flex;
    width: 100%;
    align-items: start;
    justify-content: space-between;
    margin-bottom: 90px;
    @media screen and (max-width: 1000px) {
      margin-bottom: 70px;
    }
    @media screen and (max-width: 660px) {
      display: block;
      margin-bottom: 35px;
    }
    a {
      display: block;
      width: 270px;
      height: 50px;
      color: #191919;
      font-size: 18px;
      font-weight: 500;
      border: 1px solid #3e3e3e;
      box-sizing: border-box;
      padding: 0 30px;
      line-height: 50px;
      border-radius: 25px;
      background-image: url("/img/icon/ico-rightArrow-b.png");
      background-repeat: no-repeat;
      background-position: 230px center;
      transition: 0.3s;
      @media screen and (max-width: 1000px) {
        width: 220px;
        background-position: 190px center;
      }
      @media screen and (max-width: 660px) {
        display: inline-block;
        margin-top: 40px;
        font-size: 16px;
        width: calc(50% - 5px);
        background-position: 90% center;
        padding: 0 15px;
      }
      @media screen and (max-width: 390px) {
        display: block;
        width: 100%;
        margin-top: 10px;
      }
      &:first-of-type {
        margin-bottom: 10px;
        @media screen and (max-width: 660px) {
         margin-right: 10px;
          margin-bottom: 0;
        }
        @media screen and (max-width: 390px) {
          margin-top: 30px;
        }
      }

      &:hover {
        border-color: #e2e4d0;
        background-color: #bcd330;
        color: #fff;
        background-image: url("/img/icon/ico-btnRightArrow.png");
      }
    }

    h3 {
      color: #191919;
      font-size: 42px;
      font-weight: 700;
      line-height: 1.2;
      @media screen and (max-width: 1000px) {
        font-size: 32px;
      }
      @media screen and (max-width: 660px) {
        font-size: 28px;
      }
      @media screen and (max-width: 370px) {
        font-size: 22px;
      }
      span {
        display: block;
      }

      b {
        font-size: 56px;
        font-weight: 800;
        @media screen and (max-width: 1000px) {
          font-size: 46px;
        }
        @media screen and (max-width: 1000px) {
          font-size: 36px;
        }
      }
    }
  }

  .title {
    color: #191919;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 25px;
    @media screen and (max-width: 630px) {
      font-size: 20px;
    }
  }

  ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    li {
      width: 48%;
      height: 170px;
      display: flex;
      align-items: start;
      justify-content: space-between;
      padding: 45px 75px 0 50px;
      box-sizing: border-box;
      box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.1);
      margin-bottom: 40px;
      @media screen and (max-width: 1470px) {
        padding: 35px 20px 0 20px;
        height: 180px;
      }
      @media screen and (max-width: 1145px) {
        padding: 35px 0px 0 20px;
      }
      @media screen and (max-width: 1040px) {
        width: 100%;
        padding: 35px 90px 0 60px;
      }
      @media screen and (max-width: 780px) {
        width: 100%;
        padding: 30px 50px 0 50px;
      }
      @media screen and (max-width: 630px) {
        width: 100%;
        padding: 30px 20px;
        height: auto;
        padding-right: 0;
      }
      h4 {
        color: #191919;
        font-size: 28px;
        font-weight: 700;
        line-height: 1.4;
        width: 45%;
        word-break: keep-all;
        @media screen and (max-width: 630px) {
          font-size: 20px;
          padding-right: 15px;
        }
        span {
          display: block;
          color: #bcd330;
          font-size: 18px;
          @media screen and (max-width: 630px) {
            font-size: 16px;
          }
        }
      }

      div {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
        align-items: start;
        width: 55%;

        span {
          padding: 0 15px;
          display: inline-block;
          height: 30px;
          border: 1px solid #dbdbdb;
          line-height: 30px;
          border-radius: 25px;
          color: #191919;
          font-size: 15px;
          font-weight: 400;
          margin-right: 15px;
          margin-bottom: 15px;
          white-space: nowrap;
          @media screen and (max-width: 630px) {
            margin-right: 10px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
`