import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Banner, Article01, MaxWidth, Article02, Article03, Article04} from "./style";

const Groupware = () => {
    const [ScrollY, setScrollY] = useState(0);
    const [divAni01, setDivAni01] = useState(false);
    const [divAni02, setDivAni02] = useState(false);
    const [divAni03, setDivAni03] = useState(false);
    /*LINE::스크롤감지*/
    const handleFollow = useCallback(() => {
        setScrollY(window.pageYOffset);
    }, [ScrollY]);

    useEffect(() => {
        const watch = () => {
            window.addEventListener('scroll', handleFollow);
            if(ScrollY >= 800) setDivAni01(true)
            if(ScrollY >= 1330) setDivAni02(true)
            if(ScrollY >= 1700) setDivAni03(true)
        }
        watch();
        return () => {
            window.removeEventListener('scroll', handleFollow);
        }
    }, [handleFollow]);
    return (
        <section>
            <Banner>
                <h2>
                    Security + Email + Groupware
                    <span className="fc-g">SECU E Cloud</span>
                </h2>
            </Banner>
            <MaxWidth>
                <Article01>
                    <div>
                        <h3>
                            <span>강력한 보안의 그룹웨어</span>
                            <span>SECU E Cloud</span>
                            <span>(시큐이클라우드)</span>
                        </h3>
                        <p>
                            <span>비즈니스의 성장은 안전하고 체계적인 업무 Tool을 사용하는 것에서부터 시작됩니다.</span>
                            <span>기원테크는 기업의 자산을 안전하게 관리하고, 임직원들의 체계적인 협업이 가능하도록</span>
                            <span>대내·외 업무에 필요한 모든 것을 하나로 담았습니다.</span>
                            <span>SECU E Cloud의 다양한 보안 및 편의 기능을 활용하여 </span>
                            <span>업무의 효율과 집중도를 향상시키고, 비즈니스 성장을 도모하세요.</span>
                        </p>
                    </div>
                </Article01>
            </MaxWidth>
            <Article02>
                <div>
                    <h2>비즈니스 성장을 위한 <span>‘단 하나’의 해법</span></h2>
                    <p>효율적이고 안전한 업무 환경을 <span>제공하는 SECU E Cloud</span></p>
                </div>
            </Article02>
            <Article03>
                <div>
                    <div className={divAni01 ? "active" : ""}>
                        <h2>
                            SECUrity
                            <span>SECU</span>
                        </h2>
                    </div>
                    <p>기업의 핵심 정보 자산을 안전하게 보호·관리</p>
                    <ul>
                        <li>스팸메일, 바이러스/랜섬웨어, 피싱사이트, 사기메일을 검출하는 <span>강력한 수신 보안, ‘EG-Platform(Spam/ReceiveGUARD)’</span></li>
                        <li>메일 본문 및 첨부파일에 대한 발신 차단 정책과 승인 체계로 정보 유출을 방지하는 <span>강력한 발신 보안, ‘EG-Platform(SendGUARD)’</span></li>
                    </ul>
                </div>
                <div>
                    <div className={divAni02 ? "active" : ""}>
                        <h2>
                            E-mail
                            <span>E</span>
                        </h2>
                    </div>
                    <p>다양한 메일 편의 기능으로 효율적인 메일 관리</p>
                    <ul>
                        <li>이미 발송한 메일을 회수하여 오발송 사고에 대응, 비밀번호를 설정하여 보안을 강화할 수 있는 <span>‘보안메일‘</span></li>
                        <li>팀원과 업무 메일을 효율적으로 공유하고, 효과적으로 관리·감독할 수 있는 <span>’공유메일/공용메일’</span></li>
                    </ul>
                </div>
                <div>
                    <div className={divAni03 ? "active" : ""}>
                        <h2>
                            Cloud-based Groupware
                            <span>Cloud</span>
                        </h2>
                    </div>
                    <p>내부 프로젝트의 체계적인 관리 및 협업 Tool 제공</p>
                    <ul>
                        <li>프로젝트에 투입되는 담당자의 업무를 배정하고, 결과물을 검토자 및 프로젝트 투입 인원과 공유할 수 있는 <span>‘TO DO 관리’</span></li>
                        <li>문서를 안전하게 저장소에 보관하여 언제 어디서나 최신본을 팀원과 공유 및 다운로드할 수 있는 <span>’문서관리’</span></li>
                    </ul>
                </div>
            </Article03>
            <Article04>
                <div>
                    <h3>
                        <span className="dis-b__pc">기업 성장의 비결, All-in-One 그룹웨어</span>
                        <span>SECU E Cloud에</span> 대해 더 자세히 알아보세요
                    </h3>
                    <a href="https://secuecloud.co.kr/" target="_blank">SECU E Cloud 홈페이지 바로가기</a>
                </div>
            </Article04>
        </section>
    );
};

export default Groupware;