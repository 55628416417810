import styled from "styled-components"

export const FooterWrap = styled.footer`
  width: 100%;
  padding: 60px 100px 0;
  background: #000107;
  height: 300px;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  &.main{
    @media screen and (max-width: 1370px) {
      display: none;
    }
  }
  &.sub{
    background-color: #efefef;
    .grayTxt{
      color: #333!important;
      padding: 0;
    }
  }
  @media screen and (max-width: 1300px) {
    padding: 50px 60px 0;
  }
  @media screen and (max-width: 1100px) {
    height: 400px;
    z-index: 9;
  }
  @media screen and (max-width: 600px) {
    height: 600px;
    padding: 50px 30px 0;
  }
  @media screen and (max-width: 400px) {
    height: 630px;
  }
`
export const FooterWrap_inner = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  word-break: keep-all;
  @media screen and (max-width: 1100px) {
    display: block;
  }
  & > a{
    width: 200px;
  }
  & > div:nth-of-type(1){
    width: calc(100% - 380px);
    padding-left: 80px;
    box-sizing: border-box;
    @media screen and (max-width: 1300px) {
      padding-left: 60px;
    }
    @media screen and (max-width: 1100px) {
    padding-left: 0;
      width: 100%;
    }
    button{
      font-size: 16px;
      font-weight: 500;
      color: #c3c3c3;
      background-color: transparent;
      border: 0;
      cursor: pointer;
      &:first-of-type{
        margin-right: 30px;
        @media screen and (max-width: 390px) {
          margin-right: 15px;
          margin-bottom: 10px;
        }
      }
    }
    span{
      font-size: 16px;
      font-weight: 400;
      color: #888;
      display: inline-block;
      padding-top: 15px;
      padding-right: 30px;
      vertical-align: top;
      line-height: 1.5;
      @media screen and (max-width: 1100px) {
        display: block;
      }
    }
    p{
      font-size: 16px;
      font-weight: 400;
      color: #888;
      display: inline-block;
      padding-top: 25px;
    }
  }
  & > div:nth-of-type(2){
    width: 180px;
    @media screen and (max-width: 1100px) {
      position: absolute;
      right: 40px;
      top: 55px;
    }
    @media screen and (max-width: 600px) {
      left: 30px;
      top: auto;
      bottom: 40px;
    }
    @media screen and (max-width: 400px) {
      bottom: 15px;
    }
  }
  .footerInfo{
    @media screen and (max-width: 1300px) {
      position: absolute;
      left: 60px;
      padding-top: 20px;
    }
    @media screen and (max-width: 600px) {
      left: 30px;
    }
    p{
      @media screen and (max-width: 1300px) {
        display: block;
      }
    }
  }
`
export const Select_custom = styled.div`
  width: 170px;
  height: 100%;
  background-color: #fff;
  border-radius: 25px;
  position: relative;
  z-index: 1;
  margin-top: 30px;
  padding: 0 25px;
  box-sizing: border-box;
  @media screen and (max-width: 1300px) {
    margin-top: 0px;
  }
  span{
    position: relative;
    z-index: 3;
    background-color: #fff;  
    color: #999999;
    font-weight: 500;
    font-size: 16px;
    background-image: url("/img/ico-upArrow.png");
    background-position: right center;
    background-repeat: no-repeat;
  }
  &.active{
    @media screen and (max-width: 1300px) {
      cursor: pointer;
    }
    ul{
      @media screen and (max-width: 1300px) {
        height: 70px;
      }
    }
    span{
      @media screen and (max-width: 1300px) {
        color: #555;
        background-image: url("/img/ico-downArrow.png");
      }
    }
  }
  &:hover{
    ul{
      @media screen and (min-width: 1300px) {
        height: 70px;
      }
    }
    span{
      @media screen and (min-width: 1300px) {
        color: #555;
        background-image: url("/img/ico-downArrow.png");
      }
    }
  }
  ul{
    position: absolute;
    bottom: 1px;
    padding: 0 25px;
    padding-bottom: 40px;
    background-color: #fff;
    border-radius: 25px;
    height: 0;
    overflow: hidden;
    transition: 0.3s;
    width: calc(100% - 50px);
    left: 0;
    z-index: -1;
    a{
      color: #aaa;
      font-size: 16px;
      font-weight: 500;
      &:hover{
        color: #7aa800;
      }
    }
  }
  span{
    height: 40px;
    line-height: 40px;
    text-align: left;
    width: 100%;
    display: inline-block;
  }
  li,a{
    width: 100%;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    text-align: left;
    vertical-align: bottom;
  }
  li:last-of-type{
    border-bottom: 1px solid #ddd;
  }
  li:first-of-type{
    padding-top: 10px;
  }
`
export const FooterBtnWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
`
export const FooterBtn = styled.a`
  display: inline-block;
`