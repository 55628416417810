import styled, {keyframes} from "styled-components"

const mVshake = keyframes`
  from { margin: 20px 0;}
  to { margin: 0} 
`
const flash = keyframes`
    to {
        opacity: 1;
    }
`
const overlap = keyframes`
  0%{opacity: 0;  transform: translateY(50px);}
  100%{
    opacity: 1;  transform: translateY(0px);}
`
export const Section = styled.section`
  height: 100vh;
  .scroll-arrow{
    @media screen and (max-width: 600px) {
      display: none!important;
    }
  }
  &.show{
    position: relative;
    z-index: -1;
  }
  .swiper-initialized{
    height: 100% !important;
  }
.art02.swiper-slide-active,.art02.swiper-slide-prev{
  & > article > div .overlap span{
    &:nth-of-type(1){
      animation: ${overlap} 0.7s linear alternate;
      animation-fill-mode: forwards;
    }
    &:nth-of-type(2){
      animation: ${overlap} 0.7s linear alternate;
      animation-fill-mode: forwards;
      animation-delay:0.7s;
      
    }
    &:nth-of-type(3){
      animation: ${overlap} 0.7s linear alternate;
      animation-fill-mode: forwards;
      animation-delay:1.4s;
      
    }
    &:nth-of-type(4){
      animation: ${overlap} 0.7s linear alternate;
      animation-fill-mode: forwards;
      animation-delay:2.1s;
      
    }
  }
}
  .act04.swiper-slide-active{
    .txt{
      opacity: 1;
    }
  }
  .scroll-arrow{
    animation: ${overlap} 0.7s linear alternate;
    animation-fill-mode: forwards;
    animation-delay:3s;

    img{
      animation: ${mVshake} 0.7s infinite linear alternate;
    }
  }
`
export const Article01 = styled.article` 
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.45);
  &:before{
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.45);
    width: 100%;
    height: 100%;
    z-index: 3;
  }
  video{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    z-index: 2;
  }
  .article01MainCon{
    position: absolute;
    top: 50%;
    left: 100px;
    transform: translateY(-50%);
    z-index: 3;
    @media screen and (max-width: 600px) {
      left: 30px;
    }
    h2{
      color: #fff;
      margin-bottom: 50px;
     & > span{
        display: block;
        font-weight: 400;
        &:nth-of-type(1){
          font-size: 24px;
          word-break: keep-all;
          line-height: 1.3;
          @media screen and (max-width: 820px) {
            font-size: 22px;
          }
          @media screen and (max-width: 600px) {
            font-size: 18px;
          }
          @media screen and (max-width: 400px) {
            font-size: 16px;
          }
          span{
            @media screen and (max-width: 600px) {
             display: block;
            }
          }
        }
        &:nth-of-type(2){
          font-size: 54px;
          margin:10px 0 30px;
          word-break: keep-all;
          line-height: 1.3;
          @media screen and (max-width: 820px) {
            font-size: 50px;
          }
          @media screen and (max-width: 600px) {
            font-size: 44px;
            margin: 20px 0;
          }
          @media screen and (max-width: 400px) {
            font-size: 40px;
          }
          @media screen and (max-width: 370px) {
            font-size: 38px;
          }
          span{
            @media screen and (max-width: 600px) {
              display: block;
            }
          }
        }
        &:nth-of-type(3){
          font-size: 26px;
          font-weight: 500;
          word-break: keep-all;
          line-height: 1.3;
          @media screen and (max-width: 820px) {
            font-size: 24px;
          }
          @media screen and (max-width: 600px) {
            font-size: 20px;
          }
          @media screen and (max-width: 400px) {
            font-size: 18px;
          }
        }
        b{
          font-weight: 600;
          @media screen and (max-width: 820px) {
            display: block;
          }
          @media screen and (max-width: 600px) {
            display: inline-block;
          }
        }
      }
    }
  }
  .videoBtn{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 2px solid #fff;
    box-sizing: border-box;
    background-color: transparent;
    cursor: pointer;
    vertical-align: middle;
    .color{
     display: none; 
    }
    &.stop{
      border-color: #bcd330;
    }
    &:hover{
      border-color: #bcd330;
      .color{
        display: inline;
      }
      .white{
        display: none;
      }
    }
    img{
      vertical-align: middle;
    }
  }
  .moreBtn{
    box-sizing: border-box;
    margin-left: 20px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    height: 60px;
    border-radius: 30px;
    background-color: transparent;
    border: 2px solid #fff;
    vertical-align: middle;
    cursor: pointer;
    background-image: url("/img/ico-moreBtn-w.png");
    background-repeat: no-repeat;
    padding: 20px 30px 20px 60px;
    background-position: 30px 18px;
    display: inline-block;
    text-decoration: none;
    position: relative;
    z-index: 999;
    &:hover{
      border-color: #bcd330;
      color: #bcd330;
      background-image: url("/img/ico-moreBtn-g.png");
    }
  }
  
  .scroll-arrow{
    display: inline-block;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    img{
      animation: ${mVshake} 0.7s infinite linear alternate;
    }
  }
`


export const Article02 = styled.article`
  width: 100%;
  height: 100vh;
  background-image: url("/img/img-main02.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  //background-attachment: fixed;
  position: relative;
  z-index: 3;
  & > div{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    transition: 0.5s;
  }
  .overlap span{
    color: rgba(255, 255, 255, 0.8);
    opacity: 0;
  }
  p{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #626773;
    font-weight: 500;
    font-size: 42px;
    @media screen and (max-width: 820px) {
      font-size: 34px;
    }
    @media screen and (max-width: 400px) {
      font-size: 30px;
    }
    & > span{
      overflow: hidden;
      display: block;
      white-space: nowrap;
      transition: all .1s cubic-bezier(0.06, 0.71, 0.47, .5);
      line-height: 1.5;
      letter-spacing: -1px;
      @media screen and (max-width: 600px) {
        font-size: 28px;
      }
      @media screen and (max-width: 400px) {
        font-size: 24px;
      }
      & > span{
        @media screen and (max-width: 600px) {
          display: block;
        }
      }
    }
  }
  .scroll-arrow{
    display: inline-block;
    position: absolute;
    top: auto;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    opacity: 0;
    @media screen and (max-width: 820px) {
      //display: none;
    }
    img{
      animation: ${mVshake} 0.7s infinite linear alternate;
    }
  }
`
export const Article03 = styled.article`
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 4;
  background-color: #0c132a;
  .scroll-arrow{
    display: inline-block;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    @media screen and (max-width: 820px) {
      display: none;
    }
    img{
      animation: ${mVshake} 0.7s infinite linear alternate;
    }
  }
  .swiper-slide{
    opacity: 0!important;
  }
  .swiper-slide-active{
    opacity: 1!important;
    article{
      opacity: 1;
      .txtAni1{
        opacity: 1;
        transform: translateY(0px);
        transition: 1s;
      }
      .txtAni2{
        opacity: 1;
        transform: translateY(0px);
        transition:opacity 0.6s ease-in 0.6s;
      }
    }
  }
  .swiper-pagination{
    position: absolute;
    left: 195px;
    bottom: 110px;
    z-index: 1;
    display: inline-block;
    width: auto;
    color: #626773;
    .swiper-pagination-current, .swiper-pagination-total{
      color: #fff;
      font-weight: 400;
      padding: 0 5px;
      font-size: 16px;
    }

    @media screen and (max-width: 820px) {
      bottom: 33px;
      left: 50%;
      transform: translateX(-50%);
      width: 175px;
    }
  }
  .swiper-button-prev{
    vertical-align: middle;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    background-image: url("/img/ico-prev-arrow_w.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 40px;
    height: 25px;
    position: absolute;
    left: 75px;
    bottom: 105px;
    z-index: 2;
    &.swiper-button-disabled{
      background-image: url("/img/ico-prev-arrow_g.png");
    }
    @media screen and (max-width: 820px) {
      position: absolute;
      left: 38%;
      bottom: 30px;
      margin: 0;
    }
    @media screen and (max-width: 570px) {
      left: 30%;
    }
    @media screen and (max-width: 350px) {
      left: 27%;
    }
  }
  .swiper-button-next{
    vertical-align: middle;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    background-image: url("/img/ico-next-arrow_w.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 40px;
    height: 25px;
    position: absolute;
    left: 140px;
    bottom: 105px;
    z-index: 2;
    &.swiper-button-disabled{
      background-image: url("/img/ico-next-arrow_g.png");
    }
    @media screen and (max-width: 820px) {
      position: absolute;
      left: unset;
      right: 38%;
      bottom: 30px;
      margin: 0;
    }
    @media screen and (max-width: 570px) {
      right: 30%;
    }
    @media screen and (max-width: 350px) {
      right: 27%;
    }
  }
`
export const COntrollerBot = styled.div`
  position: absolute;
  left: 70px;
  bottom: 100px;
  z-index: 1;
  @media screen and (max-width: 820px) {
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 175px;
  }
  button{
    vertical-align: middle;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    margin-right: 15px;
    &:first-of-type{
      @media screen and (max-width: 820px) {
       position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
      }
    }
    &:last-of-type{
      @media screen and (max-width: 820px) {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
      }
    
    }
  }
  & > span{
    @media screen and (max-width: 820px) {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  span{
    color: #fff;
    font-weight: 400;
    font-size: 16px;
  }
  .unit{
    color: #626773;
    padding: 0 7px;
  }
`
export const Controller = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  @media screen and (max-width: 1370px) {
    display: none;
  }
  ul{
    li{
      padding:20px 0 20px 50px;
      &:first-of-type{
        padding-top: 20px;
      }
      &.active{
        button{
          color: #fff;
          &:before{
            background-color: #fff;
          }
        }
      }
    }
    button{
      border: 0;
      background-color: transparent;
      color: #606473;
      font-weight: 500;
      font-size: 18px;
      padding-left: 30px;
      position: relative;
      cursor: pointer;
      transition: 0.2s;
      &:before{
        content: "";
        display: inline-block;
        position: absolute;
        width: 6px;
        height: 6px;
        background-color: #606473;
        top: 50%;
        transform: translateY(-50%);
        left: 0px;
        border-radius: 50%;
        transition: 0.2s;
      }
    }
  }
  div{
    width: 25px;
    height: 25px;
    border: 1px solid #fff;
    border-radius: 50%;
    position: absolute;
    top: 19px;
    left: 39px;
    transition: 0.3s;
    &:before{
      content: "";
      display: inline-block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -40px;
      width: 40px;
      height: 1px;
      background-color: #fff;
    }
  }
`
export const Article03Con = styled.article`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  &:last-of-type{
    .txtAni1{
      span:last-of-type{
        @media screen and (max-width: 420px) {
          font-size: 24px;
        }
      }
    }
    h3.txtAni1{
      @media screen and (max-width: 420px) {
      font-size: 18px;
      }
    }
    .txtAni2{
      @media screen and (max-width: 420px) {
        font-size: 14px;
      }
    }
  }
  .txtAni1{
    transform: translateY(50px);
    opacity: 0;
  }
  .txtAni2{
    opacity: 0;
    display: inline-block;
    word-break: keep-all;
    br{
      @media screen and (max-width: 400px) {
        display: none;
      }
    }
  }
  &.active{
   opacity: 1;
    .txtAni1{
      opacity: 1;
      transform: translateY(0px);
      transition: 1s;
    }
    .txtAni2{
      opacity: 1;
      transform: translateY(0px);
      transition:opacity 0.6s ease-in 0.6s;
    }
  }
`
export const Article03ConInner = styled.article`
  margin: 0 100px 0 250px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1370px) {
    margin: 0 0px 0 70px;
  }
  @media screen and (max-width: 820px) {
    margin: 0;
    flex-wrap: wrap-reverse;
    justify-content: start;
    align-items: inherit;
  }
  &.slideNo3{
   & > div:first-of-type{
      width: 45%;
    }
    & > div:last-of-type{
      width: 55%;
    }
  }
  & > div{
    width: 50%;
    @media screen and (max-width: 820px) {
      width: 100% !important;
      text-align: center;
      padding: 0 15px;
    }
    &:first-of-type{
      @media screen and (max-width: 450px) {
        height: 53%;
      }
      h2{
        & > span{
          display: block;
          word-break: keep-all;
          &:nth-of-type(1){
            font-size: 100px;
            color: #fff;
            font-weight: 600;
            margin-bottom: 20px;
            @media screen and (max-width: 1370px) {
              font-size: 90px;
            }
            @media screen and (max-width: 820px) {
              font-size: 78px;
              margin-bottom: 10px;
              margin-top: 20px;
            }
            @media screen and (max-width: 600px) {
              font-size: 60px;
            }
          }
          &:nth-of-type(2){
            font-size: 58px;
            font-weight: 400;
            line-height: 1.2;
            color: #c5c9d0;
            margin-bottom: 10px;
            @media screen and (max-width: 1370px) {
              font-size: 50px;
            }
            @media screen and (max-width: 820px) {
              font-size: 40px;
            }
            @media screen and (max-width: 600px) {
              font-size: 25px;
            }
            @media screen and (max-width: 380px) {
              font-size: 22px;
              line-height: 1.3;
            }
          }
          &:nth-of-type(3){
            font-size: 58px;
            font-weight: 600;
            color: #fff;
            @media screen and (max-width: 1370px) {
              font-size: 50px;
            }
            @media screen and (max-width: 600px) {
              font-size: 35px;
              white-space:initial;
              word-break: keep-all;
            }
            @media screen and (max-width: 380px) {
              font-size: 30px;
              white-space:initial;
              word-break: keep-all;
              line-height: 1.3;
            }
          }
        }
      }
      
      h3{
        font-size: 32px;
        font-weight: 500;
        color:#fff;
        margin:35px 0;
        white-space: nowrap;
        @media screen and (max-width: 1370px) {
          font-size: 24px;
        }  @media screen and (max-width: 380px) {
        font-size: 21px;
      }
        
        @media screen and (max-width: 820px) {
          margin:20px 0;
          line-height: 1.4;
          white-space:initial;
          word-break: keep-all;
        }
        span{
          @media screen and (max-width: 820px) {
            display: block;
          }
        }
      }
      & > span{
        font-size: 22px;
        font-weight: 400;
        color: #fff;
        line-height: 1.4;
        white-space: nowrap;
        .br{
          display: block;
        }
        span{
          @media screen and (max-width: 600px) {
            display: block;
            white-space:initial;
            word-break: keep-all;
          }
        }
        @media screen and (max-width: 1370px) {
          font-size: 18px;
        }
        @media screen and (max-width: 600px) {
          font-size: 16px;
          white-space:initial;
          word-break: keep-all;
        }
        
      }
    }
    
    &:last-of-type{
      position: relative;
      display: flex;
      align-items: end;
      justify-content: center;
      @media screen and (max-width: 450px) {
        height: 47%;
      }
      & > div{
        position: relative;
        display: inline-block;
        @media screen and (max-width: 820px) {
          width: 55%;
        }
        @media screen and (max-width: 600px) {
          width: 80%;
        }
        @media screen and (max-width: 450px) {
        /* height: 100%;
          display: flex;
          align-items: end;*/
          position: absolute;
          bottom: 0;
        }
      
        & > div{
          @media screen and (max-width: 450px) {
            position: relative;
            
          }
        }
        img{
          max-width: 100%;
        }
      }
      .main01{
        @media screen and (max-width: 820px) {
          width: 90%;
        }
      }
      .main01_2{
        position: absolute;
        top: 7%;
        left: 28%;
        max-width: 19%;
        animation: ${mVshake} 0.7s infinite linear alternate;
      }
      .main02{
        margin-top: 50px;
        margin-left: -30px;
        @media screen and (max-width: 820px) {
          margin-top: 0px;
          margin-left: 0px;
        }
      }
      .main02_2{
        position: absolute;
        top: 59%;
        max-width: 45%;
        left: 16%;
        animation: ${mVshake} 0.7s infinite linear alternate;
        @media screen and (max-width: 820px) {
          top: 53%;
          left: 20%;
        }
      }
      .main03{
        margin-top: 80px;
        @media screen and (max-width: 820px) {
          margin-top: 0px;
          margin-left: 0px;
        }
      }
      .main03_2{
        position: absolute;
        top: -14%;
        left: 41%;
        max-width: 17%;
        animation: ${mVshake} 0.7s infinite linear alternate;
        @media screen and (max-width: 820px) {
          top: -38%;
        }
      }
      .main04{
        margin-top: 140px;
        margin-left: 60px;
        width: 90%;
        @media screen and (max-width: 1370px) {
          margin-top: 120px;
          margin-left: 40px;
        }
        @media screen and (max-width: 820px) {
          margin-top: 0px;
          width: 100%;
          margin-left: 0px;
        }
      }
      .main04_2{
        position: absolute;
        top: 8%;
        left: 31%;
        max-width: 40%;
        animation: ${mVshake} 0.7s infinite linear alternate;
        @media screen and (max-width: 1370px) {
          left: 29%;
        }
        @media screen and (max-width: 820px) {
          top: -14%;
          left: 28%;
        }
      }
    }
    
  }
`
export const Article04 = styled.article`
  width: 100%;
  height: 100vh;
  background-color: #000a1d;
  position: relative;
  overflow: hidden;
  z-index: 5;
  .txt{
    opacity: 0;
    @media screen and (max-width: 1300px) {
      display: none;
    }
  }
  .mbtxt{
    display: none;
    font-size: 40px;
    @media screen and (max-width: 1300px) {
      display: block;
    }
    @media screen and (max-width: 820px) {
      font-size: 34px;
    }
    @media screen and (max-width: 600px) {
      font-size: 26px;
    }
    @media screen and (max-width: 400px) {
      font-size: 24px;
      position: relative;
      z-index: 3;
    }
    @media screen and (max-width: 350px) {
      font-size: 22px;
    }
  }
  p{
    text-align: center;
    color: #fff;
    font-weight: 400;
    font-size: 42px;
    transition:opacity 1s ease-in 0.3s;
    & > span{
      color: #fff;
      display: block;
      line-height: 1.5;
      letter-spacing: -1px;
      span{
        @media screen and (max-width: 600px) {
          display: block;
        }
      }
      @media screen and (max-width: 820px) {
        line-height: 1.6;
      }
    }
  }
  .Article04-inner{
    width: 1920px;
    height: 100%;
    max-width: 1920px;
    position: relative;
    background-position:top center;
    background-image: url("/img/img-wolrd.png");
    background-repeat: no-repeat;
    background-size: 1920PX 928PX;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    @media screen and (max-width: 700px) {
      background-image: url("/img/img-wolrd-m.png");
      background-size: 700px 800px;
    }
 
  }
/*  &:before{
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    right: 0;
    top:0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }*/
  .scroll-arrow{
    display: inline-block;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    img{
      animation: ${mVshake} 0.7s infinite linear alternate;
    }
  }
  
  .star{
    position: absolute;
    display: inline-block;
    top: 50%;
    left: 50%;
    background-color: #00ffff;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    z-index: 2;
    opacity: 0.3;
    filter: blur(0.7px);
    animation: ${flash} 0.7s ease-out alternate infinite;
    @media screen and (max-width: 700px) {
      display: none!important;
    }
    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 120%;
      height: 120%;
      background: #01cbff;
      filter: blur(4px);
      content: "";
    }
    &:before {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 140%;
      height: 140%;
      background: #005aff;
      filter: blur(6px);
      content: "";
    }
    &.star01{
      width: 5px;
      height: 5px;
      top: 175px;
      left: 885px;
    }
    &.star02{
      top: 355px;
      left: 545px;
    }
    &.star03{
      width: 5px;
      height: 5px;
      top: 325px;
      left: 705px;
    }
    &.star04{
      width: 5px;
      height: 5px;
      top: 355px;
      left: 825px;
    }
    &.star05{
      width: 6px;
      height: 6px;
      top: 345px;
      left: 1340px;
    }
    &.star06{
      width: 5px;
      height: 5px;
      top: 320px;
      left: 1830px;
    }
    &.star07{
      width: 6px;
      height: 6px;
      top: 460px;
      left: 1245px;
    }
    &.star08{
      width: 5px;
      height: 5px;
      top: 460px;
      left: 1800px;
    }
    &.star09{
      width: 5px;
      height: 5px;
      top: 595px;
      left: 475px;
    }
    &.star10{
      width: 5px;
      height: 5px;
      top: 610px;
      left: 1490px;
    }
    &.star11{
      width: 5px;
      height: 5px;
      top: 715px;
      left: 200px;
    }
    &.star12{
      width: 6px;
      height: 6px;
      top: 720px;
      left: 540px;
    }
    &.star13{
      width: 5px;
      height: 5px;
      top: 680px;
      left: 720px;
    }
    &.star14{
      width: 5px;
      height: 5px;
      top: 770px;
      left: 910px;
    }
    &.star15{
      width: 5px;
      height: 5px;
      top: 740px;
      left: 1190px;
    }
    &.star16{
      width: 5px;
      height: 5px;
      top: 730px;
      left: 1590px;
    }
  }
`
export const Article05 = styled.article`
  width: calc(100% - 40px);
  height: 100vh;
  position: relative;
  background-color: #1e2732;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 40px;
  z-index: 6;
  @media screen and (max-width: 600px) {
    width: calc(100% - 30px);
    padding-left: 30px;
  }
  & > div{
    width: 100%;
    margin-top: 80px;
  }
  .swiper-wrapper{
    margin-bottom: 60px;
    @media screen and (max-width: 600px) {
      margin-bottom: 150px;
    }
  }
  .swiper-horizontal>.swiper-scrollbar{
    width: 85%;
    background-color: #efefef;
    border-radius: 0;
    height: 3px;
  }
  .swiper-scrollbar-drag{
    background-color: #bcd330;
    border-radius: 0;
    width: 70%;
  }
  .scroll-arrow{
    display: inline-block;
    position: absolute;
    bottom: 100px;
    right: 100px;
    z-index: 3;
    width: auto;
    cursor: pointer;
    @media screen and (max-width: 1300px) {
      bottom: 30px;
    }
    @media screen and (max-width: 820px) {
      display: none;
    }
    img{
      transform: rotate(180deg);
      animation: ${mVshake} 0.7s infinite linear alternate;
    }
  }
`
export const SlideItem = styled.div`
    width: 440px;
    height: 410px;
    padding-top: 60px;
    transition: 0.3s;
  .imgWrap{
    height: 300px;
    overflow: hidden;
    @media screen and (max-width: 600px) {
      height: 200px;
    }
    img{
      width: 100%;
      height: 100%;
    }
  }
  @media screen and (max-width: 600px) {
    width: 300px;
    height: 230px;
  }
      &:hover{
       padding-top: 30px;
      }
    img{
      max-width: 100%;
    }
    p{
      color: #fff;
      font-size: 23px;
      font-weight: 300;
      margin-top: 15px;
      line-height: 1.4;
      word-break: keep-all;
      @media screen and (max-width: 820px) {
       font-size: 22px;
        font-weight: 500;
      }
      @media screen and (max-width: 600px) {
        font-size: 18px;
      }
    }
  
`
export const Article05Top = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  h3{
    font-size: 70px;
    font-weight: 700;
    color: #fff;
    display: inline-block;
    @media screen and (max-width: 820px) {
      font-size: 60px;
    }
    @media screen and (max-width: 600px) {
      font-size: 50px;
      margin-bottom: 20px;
    }
  }
`

export const BtnGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 100px;
  bottom: 0;
  @media screen and (max-width: 820px) {
    height: 40px;
    top: 10px;
  }
  button{
    @media screen and (max-width: 820px) {
      display: none;
    }
  }
  @media screen and (max-width: 600px) {
    right: 30px;
  }
  .swiper-button-prev{
    width: 38px;
    height: 24px;
    background-image: url("/img/ico-prev-arrow_w.png");
    background-repeat: no-repeat;
    &.swiper-button-disabled{
      background-image: url("/img/ico-prev-arrow_g.png");
    }
  }
  .swiper-button-next{
    width: 38px;
    height: 24px;
    background-image: url("/img/ico-next-arrow_w.png");
    background-repeat: no-repeat;
    &.swiper-button-disabled{
      background-image: url("/img/ico-next-arrow_g.png");
    }
  }
  a{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: #353d47;
    display: inline-block;
    text-align: center;
    color: #ffffff;
    font-size: 45px;
    font-weight: 300;
    text-decoration: none;
    line-height: 40px;
    margin-left: 20px;
    transition: 0.5s;
    background-image: url("/img/ico-moreBg.png");
    background-position: center;
    background-repeat: no-repeat;
    @media screen and (max-width: 820px) {
      width: 40px;
      height: 40px;
      margin-left: 10px;
      font-weight: 300;
      line-height: 0px;
      font-size: 40px;
    }
    &:hover{
      background-color: #bcd330;
      color: #fbfcf5;
    }
  }
  button{
    background-color: transparent;
    border: 0;
    cursor: pointer;
    margin-right: 10px;
  }
`