import React, {useEffect, useState} from 'react';
import {Loding, NsList} from "../style";
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import Search from "../incloud/Search";
import Pagination from "../incloud/Pagination";
import useLocalStorage from "../../hooks/useLocalStorage";
import useInput from "../../hooks/useInput";
import axios from "axios";
import useApi from "../../hooks/useApi";
import LazyImage from "../../hooks/useLazeImg";

const Newsletter = () => {
    const [url, setUrl] = useState(null);
    useEffect(() => {
        if (window.location.search) {
            setUrl(`https://apis.kiwontech.com/board/news-letter?pageNo=${currentPage}${currentSearchType ? `&searchType=${currentSearchType}` : ""}${currentSearchText ? `&searchText=${currentSearchText}` : ""}`);
        } else {
            setUrl(`https://apis.kiwontech.com/board/news-letter`);
        }
    }, [window.location.search]);
    const data = useApi(url);
    const { apiData, isValidating, mutate } = data;
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = searchParams.get('pageNo') || 1;
    const currentSearchType = searchParams.get('searchType') || "";
    const currentSearchText = searchParams.get('searchText') || "";
    const [pageItem, setPageItem] = useState(Math.ceil(apiData?.totalCount / 10));

    useEffect(() =>{
        setPageItem(Math.ceil(apiData?.totalCount / 10))
    },[url, pageItem, apiData])

    if(!apiData) return <Loding><span>LOADING...</span></Loding>
    return (
        <>
            <NsList>
                {
                    apiData.list.length !== 0 ?
                        apiData.list?.map(item =>
                            <li>
                                <Link to={`/news/news-letter/${item.idx}`}>
                                    <div className="imgWrap">
                                        <LazyImage
                                            src={`https://apis.kiwontech.com/file/download/${item.mainImgIdx}`}
                                            alt={item.title}/>
                                    </div>
                                    <h3>{item.title}</h3>
                                </Link>
                            </li>
                        ) :
                        <li className="no-data">리스트가 없습니다.</li>
                }

            </NsList>
            <Pagination pageItem={pageItem} apiData={apiData}/>

        </>
    );
};

export default Newsletter;