import React, {useCallback, useEffect, useState} from 'react';
import {Article01, Article02, H2, MaxWidth} from "./style";

const Recruit = () => {
    const [ScrollY, setScrollY] = useState(0);
    const [divAni01, setDivAni01] = useState(false);

    /*LINE::스크롤감지*/
    const handleFollow = useCallback(() => {
        setScrollY(window.pageYOffset);
    }, [ScrollY]);

    useEffect(() => {
        const watch = () => {
            window.addEventListener('scroll', handleFollow);
            if(ScrollY >= 120) setDivAni01(true)
        }
        watch();
        return () => {
            window.removeEventListener('scroll', handleFollow);
        }
    }, [handleFollow]);

    return (
        <section>
            <MaxWidth>
                <H2>
                    <span className="mb-b">혁신에 함께 도전 할</span> <span className="fc-g">기원<span>人</span></span>을 찾습니다.
                </H2>
            </MaxWidth>
            <Article01>
                <h3>기원<span>人</span>의 Keywords</h3>
                <div className="wrap">
                    <div className={"inner " + (divAni01 ? "active" : "")}>
                        <div className="list01">
                            <div>
                                <div className="ani01">
                                    <h2><span>K</span>now-how</h2>
                                    <p>
                                        <span>모든 지식과 기술을</span>
                                        자신만의 노하우로 만듭니다.
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div className="ani02">
                                    <h2><span>I</span>nterested in</h2>
                                    <p>
                                        <span>도전 과제에 흥미를 느끼고</span>
                                        주도적으로 해결합니다.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="list02">
                            <div>
                                <div className="ani03">
                                    <h2><span>W</span>orld-wide</h2>
                                    <p>
                                        <span>한계를 정하지 않고</span>
                                        더 넓은 세계로 나아갑니다.
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div className="ani04">
                                    <h2><span>O</span>pen-mind</h2>
                                    <p>
                                        <span>변화를 두려워하지 않고</span>
                                        혁신을 받아들입니다.
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div className="ani05">
                                    <h2><span>N</span>ew</h2>
                                    <p>
                                        <span>새로운 것에 도전하고</span>
                                        새로운 것을 창조합니다.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Article01>
            <Article02>
                <MaxWidth>
                    <div className="article02__top">
                        <h3>
                            <span>다양한 분야의 사람들이 모여</span>
                            <b>우리</b> 를 완성합니다.
                        </h3>
                        <div>
                            <a href="https://www.saramin.co.kr/zf_user/company-info/view?csn=Zm9pNmMzT21PclRLa3JGL01SVFc4dz09" target="_blank">사람인 채용공고</a>
                            <a href="https://www.jobkorea.co.kr/Recruit/Co_Read/C/kiwontech2?Oem_Code=C1" target="_blank">잡코리아 채용공고</a>
                        </div>
                    </div>
                    <h3 className="title">모집분야</h3>
                    <ul>
                        <li>
                            <h4>
                                Back-End 개발
                                <span>
                                    웹&솔루션 개발
                                </span>
                            </h4>
                            <div>
                                <span>
                                    #JAVA
                                </span>
                                <span>
                                    #JSP
                                </span>
                                <span>
                                    #JPA
                                </span>
                                <span>
                                    #RDB
                                </span>
                            </div>
                        </li>
                        <li>
                            <h4>
                                Front-End 개발
                                <span>
                                    웹&솔루션 개발
                                </span>
                            </h4>
                            <div>
                                <span>
                                    #RecatJS
                                </span>
                                <span>
                                    #JavaScript
                                </span>
                                <span>
                                    #UI/UX
                                </span>
                                <span>
                                    #JSP
                                </span>
                            </div>
                        </li>
                        <li>
                            <h4>
                                모바일 앱 개발
                                <span>
                                    iOS/Android
                                </span>
                            </h4>
                            <div>
                                <span>
                                    #Swift
                                </span>
                                <span>
                                    #Kotlin
                                </span>
                            </div>
                        </li>
                        <li>
                            <h4>
                                서비스/웹 기획
                                <span>
                                    웹&솔루션 기획
                                </span>
                            </h4>
                            <div>
                                <span>
                                    #UX
                                </span>
                                <span>
                                    #분석력
                                </span>
                                <span>
                                    #문서작성
                                </span>
                                <span>
                                    #커뮤니케이션
                                </span>
                                <span>
                                    #카피라이팅
                                </span>
                            </div>
                        </li>
                        <li>
                            <h4>
                                UI/UX 디자인
                                <span>
                                    웹&솔루션 디자인
                                </span>
                            </h4>
                            <div>
                                <span>
                                    #UI/UX
                                </span>
                                <span>
                                    #PhotoShop
                                </span>
                                <span>
                                    #Illustrator
                                </span>
                                <span>
                                   #Adobe XD
                                </span>
                            </div>
                        </li>
                        <li>
                            <h4>
                                서버 운영
                                <span>
                                    H/W 설치 엔지니어
                                </span>
                            </h4>
                            <div>
                                <span>
                                    #OA 유지보수
                                </span>
                                <span>
                                    #서버 엔지니어
                                </span>
                                <span>
                                    #네트워크
                                </span>
                                <span>
                                   #보안 장비
                                </span>
                            </div>
                        </li>
                        <li>
                            <h4>
                                솔루션 엔지니어
                                <span>
                                    기술 지원
                                </span>
                            </h4>
                            <div>
                                <span>
                                    #이메일 보안
                                </span>
                                <span>
                                    #네트워크
                                </span>
                                <span>
                                    #관제
                                </span>
                                <span>
                                   #고객 응대
                                </span>
                                <span>
                                   #분석력
                                </span>
                            </div>
                        </li>
                        <li>
                            <h4>
                                영업
                                <span>
                                    고객관리 및 마케팅
                                </span>
                            </h4>
                            <div>
                                <span>
                                    #고객관리
                                </span>
                                <span>
                                    #IT지식
                                </span>
                                <span>
                                    #마케팅
                                </span>
                                <span>
                                   #문서작성
                                </span>
                                <span>
                                   #영업자료 제작
                                </span>
                            </div>
                        </li>
                    </ul>
                </MaxWidth>
            </Article02>
        </section>
    );
};

export default Recruit;