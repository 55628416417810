import styled, {keyframes} from "styled-components"

const articleAni1 = keyframes`
  0%{
    opacity: 0;
    transform: translateY(100px);
  }
  100%{
    opacity: 1;
    transform: translateY(0px);
  }
`

const articleAni2 = keyframes`
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
`
export const MaxWidth =styled.div`
  max-width: 1480px;
  padding: 0 40px;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 530px) {
    max-width: 1460px;
    padding: 0 30px;
  }
`
export const Banner = styled.div`
  width: 100%;
  background-repeat:no-repeat;
  background-size: cover;
  padding: 0 100px;
  background-repeat:no-repeat;
  background-size: cover;
  padding: 0 100px;
  box-sizing: border-box;
  background-image: url("/img/img-banner03.png");
  h2{
    color:#fff;
    font-size: 60px;
    font-weight: 700;
    padding: 340px 0 90px;
    line-height: 1.4;
    animation: ${articleAni2} 2s;
    @media screen and (max-width: 840px) {
      padding: 255px 0 45px;
      font-size: 44px;
    }
    @media screen and (max-width: 640px) {
      padding: 270px 0 30px;
      font-size: 32px;
    }
    span{
      @media screen and (max-width: 640px) {
        display: block;
      }
    }
    .fc-g{
      display: block;
      color: #bcd330;
    }
  }
  @media screen and (max-width: 1300px) {
    padding: 0 40px;
  }
  @media screen and (max-width: 840px) {
    background-position: -300px;
  }
  @media screen and (max-width: 530px) {
    padding: 0 30px;
    background-position: -550px;
  }
`
export const Article01 = styled.article`
  margin: 100px 0;
  animation: ${articleAni1} 2s;
  @media screen and (max-width: 1100px) {
    margin: 50px 0 80px;
  }
  @media screen and (max-width: 530px) {
    margin: 35px 0 50px;
  }
  & > div{
    display: flex;
    @media screen and (max-width: 1100px) {
      display: block;
    }
  }
  h3{
    font-size: 42px;
    font-weight: 700;
    color: #222;
    width: 40%;
    line-height: 1.2;
    @media screen and (max-width: 1100px) {
      width: 100%;
      margin-bottom: 30px;
      font-size: 34px;
    }
    @media screen and (max-width: 530px) {
      font-size: 30px;
    }
    span{
      display: block;
      word-break: keep-all;
      &:last-of-type{
        font-weight: 500;
        font-size: 22px;
        @media screen and (max-width: 1100px) {
          font-size: 18px;
        }
        @media screen and (max-width: 530px) {
          font-size: 16px;
        }
      }
    }
  }
  p{
    width: 60%;
    @media screen and (max-width: 1100px) {
      width: 100%;
    }
    span{
      color: #636363;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.3;
      display: block;
      word-break: keep-all;
      @media screen and (max-width: 1100px) {
        font-size: 16px;
      }
      &:nth-of-type(1), &:nth-of-type(3){
        margin-bottom: 20px;
      }
    }
  }
`
export const Article02 = styled.article`
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-image: url("/img/img-groupware01.png");
  background-size: cover;
  animation: ${articleAni1} 2s;
  @media screen and (max-width: 1000px) {
    height: 250px;
  }
  h2{
    color: #8d97a3;
    font-size: 60px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 15px;
    line-height: 1.3;
    @media screen and (max-width: 1000px) {
      font-size: 40px;
    }
    @media screen and (max-width: 600px) {
      font-size: 32px;
    }
    span{
      color: #fff;
      @media screen and (max-width: 600px) {
        display: block;
      }
    }
  }
  p{
    color: #8d97a3;
    font-size: 28px;
    font-weight: 500;
    text-align: center;
    line-height: 1.3;
    @media screen and (max-width: 1000px) {
      font-size: 20px;
    }
    @media screen and (max-width: 600px) {
      font-size: 16px;
    }
    span{
      @media screen and (max-width: 600px) {
        display: block;
      }
    }
  }
`
export const Article03 = styled.article`
  margin: 140px 0;
  @media screen and (max-width: 600px) {
    margin: 70px 0;
  }
  & > div{
    margin-bottom: 180px;
    @media screen and (max-width: 1000px) {
      margin-bottom: 150px;
    }
    &:last-of-type{
      margin-bottom: 140px;
      @media screen and (max-width: 1000px) {
        margin-bottom: 90px;
      }
    }
    div{
      border-bottom: 3px solid #ebebeb;
      padding-bottom: 35px;
      position: relative;
      &:before{
        content: "";
        display: inline-block;
        height: 3px;
        width: 0%;
        position: absolute;
        bottom: -3px;
        transition: 1.5s;
        left: 0;
      }
      &.active:before{
        width: 100%;
      }
      &.active h2 span{
        opacity: 1;
      }
    }
    h2{
      color: #ebebeb;
      font-size: 100px;
      font-weight: 500;
      position: relative;
      display: inline-block;
      word-break: keep-all;
      @media screen and (max-width: 1000px) {
        font-size: 80px;
      }
      @media screen and (max-width: 600px) {
        font-size: 56px;
      }
      span{
        font-weight: 600;
        position: absolute;
        left: 5px;
        top: 5px;
        opacity: 0;
        transition: 1s;
      }
    }
    &:nth-of-type(1){
      margin-left: 260px;
      @media screen and (max-width: 1300px) {
        margin-left: 40px;
      }
      @media screen and (max-width: 600px) {
        margin-left: 30px;
      }
      div{
        &:before{
          background-color:#3f6eb5 ;
        }
      }
      h2{
        span{
          color: #3f6eb5;
        }
      }
      li{
        padding-left: 30px;
        background-position: left 3px;
        margin-right: 40px;
        span{
          color: #3f6eb5;
        }
      } 
    }
    &:nth-of-type(2){
      margin-right: 260px;
      @media screen and (max-width: 1300px) {
        margin-right: 40px;
      }
      @media screen and (max-width: 600px) {
        margin-right: 30px;
      }
      div{
        text-align: right;
        &:before{
          right: 0;
          left: auto;
          background-color: #49bdef;
        }
      }
      h2{
        span{
          color: #49bdef;
        }
      }
      li{
        padding-right: 30px;
        background-position: right 3px;
        text-align: right;
        margin-left: 40px;
        span{
          color: #49bdef;
        }
      }
      p{
        text-align: right;
      }
    }
    &:nth-of-type(3){
      margin-left: 260px;
      @media screen and (max-width: 1300px) {
        margin-left: 40px;
      }
      @media screen and (max-width: 600px) {
        margin-left: 30px;
      }
      div{
        &:before{
          background-color: #3f6eb5;
        }
      }
      
      h2{
        span{
          color: #3f6eb5;
        }
      }
      li{
        padding-left: 30px;
        background-position: left 3px;
        margin-right: 40px;
        line-height: 1.3;
        span{
          color: #3f6eb5;
        }
      }
    }
    p{
      color: #222;
      font-size: 32px;
      font-weight: 600;
      margin: 45px 0 30px;
      word-break: keep-all;
      @media screen and (max-width: 1000px) {
        font-size: 26px;
        margin: 45px 0 20px;
      }
      @media screen and (max-width: 600px) {
        font-size: 22px;
      }
    }
    ul{
      li{
        color: #222;
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 15px;
        background-image: url("/img/icon/ico-groupware.png");
        background-repeat: no-repeat;
        word-break: keep-all;
        line-height: 1.3;
        @media screen and (max-width: 1000px) {
          font-size: 18px;
        }
        @media screen and (max-width: 600px) {
          font-size: 16px;
        }
      }
    }
  }
`
export const Article04 = styled.article`
  width: 100%;
  height: 350px;
  background-image: url("/img/img-groupware02.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 650px) {
    height: 300px;
  }
  div{
    text-align: center;
  }
  h3{
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 32px;
    font-weight: 600;
    line-height: 1.5;
    @media screen and (max-width: 1100px) {
      font-size: 30px;
    }
    @media screen and (max-width: 650px) {
      font-size: 26px;
    }
    @media screen and (max-width: 400px) {
      font-size: 24px;
    }
    span{
      @media screen and (max-width: 650px) {
        display: block;
      }
   }
    .dis-b__pc{
      font-size: 18px;
      font-weight: 400;
      display: block;
      @media screen and (max-width: 650px) {
        font-size: 16px;
      }
      @media screen and (max-width: 400px) {
        font-size: 14px;
      }
    }
  }
  a{
    display: inline-block;
    height: 60px;
    line-height: 60px;
    width: 330px;
    border: 1px solid #fff;
    background-color: transparent;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    border-radius: 30px;
    padding: 0 25px;
    text-align: left;
    box-sizing: border-box;
    background-image: url("/img/icon/ico-btnRightArrow.png");
    background-repeat: no-repeat;
    background-position: 295px center;
    margin-top: 30px;
    &:hover{
      background-image: url("/img/icon/ico-btnRightArrow-g.png");
      color: #bcd330;
      border-color: #bcd330;
      transition: 0.3s;
    }
    @media screen and (max-width: 650px) {
      font-size: 16px;
      height: 45px;
      line-height: 45px;
      width: 300px;
      background-position: 265px center;
    }
  }
`