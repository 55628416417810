import React from 'react';
import {Article01, Article02, Article02Item, Banner, MaxWidth, Article03, Article03Item, Article04} from "./style";
import Form from "./Form";

const Erp = () => {
    return (
        <section>
            <Banner>
                <h2>
                    Smart Work-Flow System
                    <span className="fc-g">ERP</span>
                </h2>
            </Banner>
            <MaxWidth>
                <Article01>
                    <div>
                        <h3>
                            <span>효율적인 자원 통합 관리</span>
                            <span>ERP</span>
                            <span>(Enterprise Resource Planning)</span>
                        </h3>
                        <p>
                            <span>스마트하고 체계적인 자원 관리는 업무 생산성을 향상시키고, 기업의 경쟁력을 키워줍니다.</span>
                            <span>기원테크는 컨설팅부터 구축, 운영 및 유지보수까지 전 과정을 거쳐</span>
                            <span>다양한 산업군의 Work-Flow에 최적화된 ERP를 제공합니다.</span>
                            <span>30년간의 풍부한 경험과 노하우를 토대로 완성된 ERP를 통해 더 빠르고 스마트하게 기업을 성장시키세요.</span>
                        </p>
                    </div>
                </Article01>
            </MaxWidth>
            <Article02>
                <div className="article02__inner">
                    <h3>
                        일을 더 쉽게, 체계적으로
                        <span>ERP를 통해 업무 생산성을 향상시키세요.</span>
                    </h3>
                    <Article02Item>
                        <li>
                            <div>
                                <div>
                                    <h4>
                                        <span>업무</span>
                                        프로세스의 일원화
                                    </h4>
                                    <p>
                                        <span className="br">인사, 재무, 생산, 판매, 운영 등 전 범위의 업무</span>
                                        <span className="br">프로세스를 하나의 시스템에 구축하여</span>
                                        <span className="br">정보와 자원을 효과적으로 공유</span>
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div>
                                <div>
                                    <h4>
                                        <span>유기적인</span>
                                        실시간 업무 처리
                                    </h4>
                                    <p>
                                        <span className="br">영업사원이 발주한 하나의 상품에 대해</span>
                                        <span className="br">생산부터 유통, 재고 관리까지 모든 업무가</span>
                                        <span className="br">유기적으로 실시간 처리, 업무 생산성 향상</span>
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div>
                                <div>
                                    <h4>
                                        <span>체계적인</span>
                                        자원 관리
                                    </h4>
                                    <p>
                                        <span className="br">정보의 누락이나 중복 처리 없이 실시간으로</span>
                                        <span className="br">재무 현황을 파악하고 제품 생산, 재고 관리 등</span>
                                        <span className="br">모든 투입 자원의 체계적이고 효율적인 관리</span>
                                    </p>
                                </div>
                            </div>
                        </li>
                    </Article02Item>
                </div>
            </Article02>
            <Article03>
                <div className="article03Top">
                    <h3>
                        <span>기원테크 ERP</span> 가 돋보이는 이유,
                    </h3>
                    <p>
                        고객의 성공에 힘이 될 수 있도록 <span>최고의 ERP를 합리적으로 구축합니다.</span>
                    </p>
                </div>
                <Article03Item>
                    <li>
                        <h4>
                            <span>30년의 노하우</span>
                        </h4>
                        <p>
                            <span>다양한 산업군에 대한 풍부한 이해를 바탕으로</span>
                            <span>고객의 Needs와 기업 업무 환경에 최적화된</span>
                            ERP 시스템 제안
                        </p>
                    </li>
                    <li>
                        <h4>
                            <span>필요한 것만, 필요한 만큼만</span>
                        </h4>
                        <p>
                            <span>고객에게 필요한 기능을 자유롭게 추가하고</span>
                            <span>변경하여 사용자에게 꼭 맞는 사용감과</span>
                            합리적인 비용으로 구축
                        </p>
                    </li>
                    <li>
                        <h4>
                            <span>확장 및 사후 관리 무료 지원</span>
                        </h4>
                        <p>
                            <span>고객의 비즈니스 성장 및 확장에 따른</span>
                            <span>ERP 추가 개발을 무료로 지원,</span>
                            구축 후 지속적인 무료 사후 관리
                        </p>
                    </li>
                </Article03Item>
            </Article03>
            <Form/>
        </section>
    );
};

export default Erp;