import React, {useCallback, useState} from 'react';
import {Article01, Banner, MaxWidth, Article02, Article02Item, Article03, moreBtn, MoreBtn, BtnWrap} from "./style";
import Form from "./Form";


const Si = () => {
    const [listHidden, setListHidden] = useState(false);

    const onClickList = useCallback(() => {
        setListHidden(prev => !prev);
    },[listHidden])

    return (
        <section>
            <Banner>
                <h2>
                    Solution for Digital Innovation
                    <span className="fc-g">SI</span>
                </h2>
            </Banner>
            <MaxWidth>
                <Article01>
                    <div>
                        <h3>
                            <span>디지털 통합 시스템 개발</span>
                            <span>SI</span>
                            <span>(System Integration)</span>
                        </h3>
                        <p>
                            <span>Work-Flow의 선순환을 위해 Digital System은 필수입니다.</span>
                            <span>기원테크는 처음부터 끝까지 ‘직접‘ 혁신을 만들어가는 기원人의 개발力으로</span>
                            <span>고객의 비전이 녹아든 고도화된 시스템을 구현합니다.</span>
                            <span>복잡한 시스템은 심플하게, 정적인 사이트는 역동적으로 고객에게 특별한 경험을 선사합니다.</span>
                        </p>
                    </div>
                </Article01>
            </MaxWidth>
            <Article02>
                <div className="article02__inner">
                    <h3>
                        <span>기원人만의 개발力</span>
                        최상의 결과물은 최고의 팀이 만듭니다.
                    </h3>
                    <Article02Item>
                        <li>
                            <h4>
                                복합적인 과제를 심플하게
                            </h4>
                            <p>
                                <span>Technical Consultant의 경험과 전문성을</span>
                                <span>바탕으로 다양한 시스템을 요하는</span>
                                <span>복합적인 과제를 심플하고 튼튼하게 설계</span>
                            </p>
                        </li>
                        <li>
                            <div>
                                <div>
                                    <h4>
                                        아이디어의 정확한 구현
                                    </h4>
                                    <p>
                                        <span>아이디어에 세련되고 생동감 있는 UI와</span>
                                        <span>한계가 없는 Developer의 새로운 기술을</span>
                                        <span>접목하여 고객의 비전을 정확하게 구현</span>
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div>
                                <div>
                                    <h4>
                                        고객의 경험을 확장
                                    </h4>
                                    <p>
                                        <span>고객의 경험을 Digital System에 확장하여 디자인, </span>
                                        <span>Digital System과 긴밀한 연결고리를 형성하여</span>
                                        <span>쉬운 사용감과 진정한 가치를 선사</span>
                                    </p>
                                </div>
                            </div>
                        </li>
                    </Article02Item>
                </div>
            </Article02>
            <Article03>
                <div className="article03Top">
                    <h3>
                        기원테크의 노하우로 쌓인 <span>Digital System</span>
                    </h3>
                    <p>
                        우리와 고객이 함께 만들어온 성공은 이렇습니다.
                    </p>
                </div>
                <ul>
                    <li>
                        <a href="http://www.kma.org/" target="_blank">
                            <img src="/img/img-siLogo01.png" alt="대한의사협회"/>
                        </a>
                    </li>
                    <li>
                        <a href="http://doc-lic.kma.org/" target="_blank">
                            <img src="/img/img-siLogo02.png" alt="대한의사협회 면허신고센터"/>
                        </a>
                    </li>
                    <li>
                        <a href="http://edu.kma.org/" target="_blank">
                            <img src="/img/img-siLogo03.png" alt="대한의사협회 교육센터"/>
                        </a>
                    </li>
                    <li>
                        <a href="http://term.kma.org/" target="_blank">
                            <img src="/img/img-siLogo04.png" alt="대한의사협회 의학용어위원회"/>
                        </a>
                    </li>
                    <li>
                        <a href="http://www.kpta.co.kr/center" target="_blank">
                            <img src="/img/img-siLogo05.png" alt="대한물리치료사협회"/>
                        </a>
                    </li>
                    <li>
                        <a href="http://www.kpta.co.kr/license" target="_blank">
                            <img src="/img/img-siLogo06.png" alt="대한물리치료사협회 면허신고센터"/>
                        </a>
                    </li>
                    <li>
                        <a href="http://edu.kpta.co.kr/" target="_blank">
                            <img src="/img/img-siLogo07.png" alt="대한물리치료사협회 교육센터"/>
                        </a>
                    </li>
                    <li>
                        <a href="http://www.kcha.or.kr/center" target="_blank">
                            <img src="/img/img-siLogo08.png" alt="대한병원행정관리자협회"/>
                        </a>
                    </li>
                    {
                        listHidden &&
                            <>
                                <li>
                                    <a href="https://www.redcross.or.kr/learn/edu/edu.do" target="_blank">
                                        <img src="/img/img-siLogo09.jpg" alt="대한적십자사"/>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.teps.or.kr/" target="_blank">
                                    <img src="/img/img-siLogo10.png" alt="텝스"/>
                                    </a>
                                </li>
                            </>
                    }
                </ul>
            </Article03>
            <BtnWrap>
                <MoreBtn onClick={onClickList} className={listHidden ? "active" : ""} title="리스트전체보기/숨기기">
                    리스트전체보기/숨기기
                </MoreBtn>
            </BtnWrap>
            <Form/>
        </section>
    );
};

export default Si;