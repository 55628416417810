import styled, {keyframes} from "styled-components"

export const Wrap = styled.div`
  width: 650px;
  height: 420px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #fff;
  z-index: 10;
  @media screen and (max-width: 700px) {
    width: 450px;
  }
  @media screen and (max-width: 450px) {
    width: 350px;
    height: 350px;
  }
  @media screen and (max-width: 350px) {
    width: 300px;
    height: 300px;
  }
  h2{
    color: #282828;
    font-size: 32px;
    font-weight: 700;
    padding-top: 100px;
    background-image: url("/img/img-suc.png");
    background-repeat: no-repeat;
    background-position: center top;
    margin-bottom: 15px;
    @media screen and (max-width: 700px) {
      font-size: 30px;
    }
    @media screen and (max-width: 450px) {
      font-size: 24px;
      padding-top: 70px;
      background-size: 45px;
    }
    @media screen and (max-width: 350px) {
      font-size: 20px;
      padding-top: 50px;
      background-size: 40px;
    }
  }
  p{
    & > span{
      color: #515151;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.4;
      display: block;
      span{
        @media screen and (max-width: 350px) {
          display: block;
        }
      }
      @media screen and (max-width: 450px) {
        font-size: 14px;
      }
    }
  }
  button{
    width: 140px;
    height: 40px;
    background-color: #333333;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    border: 0;
    border-radius: 25px;
    margin-top: 50px;
    cursor: pointer;
    @media screen and (max-width: 450px) {
      margin-top: 30px;
    }
    @media screen and (max-width: 350px) {
      height: 35px;
      font-size: 15px;
    }
  }
`